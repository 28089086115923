import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from './views/home';
import Chapter1 from './views/ch1';
import Chapter2 from './views/ch2';
import Chapter3 from './views/ch3';
import { BrowserRouter } from 'react-router-dom';
import { AppContext } from './components/contexts';
import logo from './resources/logo.png';
import logoBlack from './resources/logoBlack.png';
import { useEffect, useState } from 'react';
import Menu from "./components/general/menu";
import { chapterList } from "./data";
import ReactGA from "react-ga4";
import logoGoogleWhite from './resources/google-org.png';

function App() {

  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    ReactGA.initialize("G-S4QJ0P76GV");
}, []);

  return (
    <BrowserRouter>
      <AppContext.Provider value={{logo, logoBlack, menuOpen, setMenuOpen, logoGoogleWhite}}>
          <Menu fill='var(--deepblue)' list={chapterList} transition={menuOpen}/>
          <Routes>
              <Route 
                exact 
                path='/'
                element={
                    <Home/>
                } 
              />
              <Route 
                exact 
                path='/ch1'
                element={
                    <Chapter1/>
                }
              />
              <Route 
                exact 
                path='/ch2'
                element={
                    <Chapter2/>
                  } 
              />
              <Route 
                exact 
                path='/ch3'
                element={
                    <Chapter3/>
                } 
              />
          </Routes>
      </AppContext.Provider>
    </BrowserRouter>
  );
}

export default App;
