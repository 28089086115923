import * as d3 from 'd3';
import { palette } from '../../../../utilities';

//Load SVG data and provide it to react
function loadSVG(svg, setData) {
    d3.svg(svg).then(data => {
        setData(data);
    });
};

function setUp(ref, svg, svgRef, palette, startAnim, animState) {
    let container = ref.current;
    startAnim(false);



    selectPathsAnd(svg, (selection) => {
        selection.attr('fill-opacity', selection.attr('class') ? 1 : 0);
        let color = selection.attr('class') ? '#fff' : randomFill(palette); //Here is where you figure color logic i.e if it is random or something else.
        selection.attr('fill', color);
    });

    svgRef.current = container.appendChild(svg.documentElement);

    startAnim(true);
}

function selectPathsAnd (svg, func) {
    const paths = svg.querySelectorAll('path');

    paths.forEach((path, index) => {
        const selection = d3.select(path);
        func(selection, index);
    });
}

function animate(svg) {
    selectPathsAnd(svg, (selection, index) => {
        let animation = createAnimation(selection, index);
        animation();
    });
}

function createAnimation (path, i) {
    return function animate() {
        createTransition(path, {
            i: i, del: 50, dur: 600
        }).call(attrs, {
            "stroke-width": function() { return 1+Math.random() * 3},
            "stroke": () => randomFill(palette)
        }).on("end", function() {
            createTransition(path, {
                i: i, del: 25, dur: 500
            }).call(attrs, {
                "fill-opacity": () => {  return path.attr('class') ? 0 : 1 },
                "fill": () => randomFill(palette),
                "stroke": "#fff",
                "stroke-width": 4,
            }).on("end", function() {
                let recursive = () => {
                    createTransition(path, {
                        i: i, del: 450, dur: 1000
                    })
                    .call(attrs, {
                        'fill': () => randomFill(palette)
                    })
                    .on("end", recursive);
                }
                recursive();
            });
        });
    }

    function createTransition(
        selection,
        { del = 30, dur = 100, i = 1, ease = d3.easeCubicInOut} = {}
    ) {
        return selection.transition()
                    .ease(ease)
                    .delay(i * del)
                    .duration(dur);
    }

    function attrs(selection, attrsObj) {
        const attrsList = Object.entries(attrsObj);
        attrsList.forEach(([attr, value]) => {
          selection.attr(attr, value);
        });
      }
}

function cleanUp(ref) {
    let parent = ref.current;
    d3.select(parent)
        .selectAll("*")
        .remove();
}

function randomFill(palette) {
    return palette[Math.floor(Math.random() * palette.length)];
}


/*function getPathAnimation(path, palette, delayFactor) {

    const ease = d3.easeCubicInOut;

    function animate() {
        const selection = d3.select(path);

        let color = getRandomColor(palette);

        selection.transition()
            .ease(ease)
            .delay(delayFactor * 200)
            .duration(1000)
            .attr('fill', () => getRandomColor(palette))
            .on('end', animate);
    }

    return animate;
};

function getRandomColor(palette) {
    return palette[Math.floor(Math.random() * palette.length)];
} */

export {
    setUp,
    loadSVG,
    cleanUp,
    animate
}