import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination, Autoplay, Keyboard } from 'swiper/modules';
import styled from 'styled-components';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const ngosData = [
  {
    name: "Espacio Mujeres para una Vida Digna Libre de Violencia",
    mission: "They seek to influence the construction of a society that contemplates the gender perspective, which favors the free and worthy development of women, children and adolescents through comprehensive care in social, legal, educational, psychological, medical and training for self-management and employment.",
    website: "https://www.espaciomujeres.org/",
    quote: "Violence against women is one of the most serious and extended problems in Mexico, an issue that touches us all."
  },
  {
    name: "Casa Gaviota un Vuelo sin Violencia",
    mission: "They work integrally in the eradication of gender violence nationwide through legal and psychological accompaniment, awareness of gender violence and work training workshops for women.",
    website: "https://www.casagaviota.org/",
    quote: "Men and women moving for peace, justice, equality and women's rights."
  },
  {
    name: "Pro Ayuda a la Mujer Origen, A.C.",
    mission: "They integrally empower women who suffer violence, poverty or are in a vulnerable situation, through awareness campaigns, psychological, legal and medical advice, realization of workshops for human, economic and community development, among other actions.",
    website: "https://redes.dh.tamu.edu/node/250",
    quote: "Helping a single woman to change her life could make a difference for all the next generations."
  },
  {
    name: "Fundación Diarq",
    mission: "They work in the prevention, detection and attention of family and gender violence; giving specialized care services, family psychological care, legal advice, training workshops, and psycho-educational workshops.",
    website: "http://fdiarq.org/",
    quote: "That women and men live in dignity and interact with harmony, respect, and equal opportunities."
  },
  {
    name: "Gendes A.C",
    mission: "They work with men who promote processes of reflection, intervention, research and incidence from a gender perspective with emphasis on masculinities and human rights, to promote and strengthen egalitarian relationships that contribute to social development.",
    website: "https://gendes.org.mx/",
    quote: "We take care of each other, we built ourselves."
  },
  {
    name: "The Hunger Project México (THP)",
    mission: "They work to end hunger and poverty through the empowerment of people in rural areas, especially women, with a holistic approach of three elements: leadership of rural and indigenous women, community mobilization for self-sufficiency, and effective alliances with local governments.",
    website: "https://thp.org.mx/",
    quote: "Hunger is more than lack of food. It is about people."
  },
  {
    name: "Asociacion Mexicana de Bancos de Alimentos",
    mission: "They rescue food to improve nutrition in institutions and communities. They reinforce and improve the food of almost two million Mexicans in a recurring way.",
    website: "https://bamx.org.mx/",
    quote: "Being hungry is the product of systemic violence. Having a meal can make the difference in your day to face all your challenges."
  },
  {
    name: "Fundación Nutrición y Vida",
    mission: "They combat hunger and malnutrition, respecting beliefs and cultures, to contribute to improving the living conditions of those who have less through food rescue.",
    website: "http://fnvac.org/sitio/",
    quote: "In Mexico, thousands of people die daily due to hunger and malnutrition. They need you. Help them."
  },
  {
    name: "Asociacion Mexicana de Transformación Rural y Urbana A.C. (Amextra)",
    mission: "They promote sustainable change processes, potentializing community leadership and local participation, which transforms living conditions of marginalized communities with a compassionate approach to gender and justice equity, health and nutrition, education, and environmental programs.",
    website: "https://www.amextra.org/",
    quote: "We are convinced that the transformation begins by meeting the basic needs through community participation."
  },
  {
    name: "Fondo para la Paz",
    mission: "They promote the development of indigenous communities living in extreme poverty, increasing people's abilities to generate their own living conditions through actions aimed at promoting equal opportunities and sustainable development.",
    website: "https://www.fondoparalapaz.org/",
    quote: "We promote deep changes, which allow people to improve their life, with comprehensive perspectives and a gender approach."
  }
];

const StyledContainer = styled.div`
  position: relative;
  height: min-content;
`;

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: min-content;
  margin: 20px auto;
  padding: 50px 0px 50px 0px;

  .swiper-button-next, .swiper-button-prev {
    background-color: white;
    padding: 10px;
    transform: scale(0.8);
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  text-align: center;
  font-size: 18px;
  border-radius: 1.5625rem;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 1.5625rem 1.75rem;
  flex-direction: column;
  align-items: flex-start;
  height: max-content;
  width: 350px!important;

  a{
    text-decoration: none;
  }

  background: #FFF; /* Default background color for all slides */
  &:nth-child(odd) {
    background: #00739F; /* Blue background for odd slides */

    h3{
      color: white; /* Change text color to white for odd slides */
    }

    span, p {
      color: lightgrey;
    }

  }
`;

const Quotation = styled.h3`
  font-family: Georgia;
  font-size: 2.10313rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;

  &::before {
    content: '“ ';
  }

  &::after {
    content: '”';
  }
`;

const Organization = styled.p`
  font-size: 16px;
  margin-top: 10px;
  text-align: left;
  color: var(--ts-blue-dark-blue, #00739F);
  font-family: Georgia;
  font-size: 1.16838rem;
  font-style: italic;
  opacity: 1;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

const DescriptionToggle = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: start;
  width: 100%;
  justify-content: space-between;

  cursor: pointer;
  font-family: Helvetica;
  font-size: 0.93469rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140.625%; /* 1.31444rem */

  input[type='checkbox'] {
    display: none;
  }
`;

const ArrowIcon = styled.span`
  font-size: 24px;
  margin-right: 10px;
`;

const AdditionalDescription = styled.p`
  border-top: 1px solid #000;
  text-align: left;
  padding-top: 20px;
  width: 100%;
  color: var(--ts-gray-dark-gray, #555);
  font-size: 1.05106rem;
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  line-height: 140.625%;
    margin-top: 10px;
`;

export default function Organizations() {
  const [showDescription, setShowDescription] = useState(Array(ngosData.length).fill(false));

  const handleDescriptionToggle = (index) => {
    const newShowDescription = [...showDescription];
    newShowDescription[index] = !newShowDescription[index];
    setShowDescription(newShowDescription);
  };

  return (
    <StyledContainer>
      <StyledSwiper
        modules={[Keyboard, Pagination, Navigation, Autoplay]}
        centeredSlides={true}
        slidesPerView='auto'
        keyboard={{
          enabled: true,
        }}
        spaceBetween={60}
        navigation={true}
        className="mySwiper"
      >

        {ngosData.map((ngo, index) => (
          <StyledSwiperSlide key={index}>
            <Quotation>{ngo.quote}</Quotation>
            <a target="_blank" rel='noreferrer' href={ngo.website} alt={ngo.name}>
              <Organization>{`« ${ngo.name} »`}</Organization>
            </a>

            <DescriptionToggle style={index % 2 !== 0 ? {color: 'var(--ts-brand-color-text-color)'} : {color: '#fff'}} $index={index} onClick={() => handleDescriptionToggle(index)}>
              Show Description
              <input type="checkbox" checked={showDescription[index]} onChange={() => handleDescriptionToggle(index)} />
              <ArrowIcon>{showDescription[index] ? <FiChevronUp /> : <FiChevronDown />}</ArrowIcon>
            </DescriptionToggle>

            {showDescription[index] && <AdditionalDescription>{ngo.mission}</AdditionalDescription>}
          </StyledSwiperSlide>
        ))}

      </StyledSwiper>
    </StyledContainer>
  );
}
