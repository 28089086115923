const size = {
    never: '20px',
    fold: '150px',
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopM: '1300px',
    laptopL: '1440px',
    desktop: '1600px',
    desktopL: '2560px'
  }

  export const device = {
    never: `(min-width: ${size.never})`,
    fold: `(min-width: ${size.fold})`,
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    tabletH: `(min-height: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopM: `(min-width: ${size.laptopM})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktopL})`
  };

  export const applyClasses = (styles, classNames) => {
      let classList = "";
      classNames.forEach((el, i) => {
          const sep = i === 0 ? "" : " ";
          classList = classList + sep + styles[el];
      });
      return classList;
  }

  export const getStateData = ( data, factor = ['young', 'adult', 'old'] ) => {
    const d = data.properties;
    const young_i = factor.includes('young') ? d['fi_women_0_17'] : 0; 
    const young_p = factor.includes('young') ? d['women_0_17'] : 0;
    const adult_i = factor.includes('adult') ? d['fi_women_18_64'] : 0; 
    const adult_p = factor.includes('adult') ? d['women_18_64'] : 0;
    const old_i = factor.includes('old') ? d['fi_women_65_plus'] : 0; 
    const old_p = factor.includes('old') ? d['women_65_plus'] : 0;

    // console.log(d.State);
    const insecure = young_i + adult_i + old_i;
    const population = young_p + adult_p + old_p;
    const percentage = insecure/population * 100;
    let ret = { insecure: insecure, population: population, percentage: percentage };
    return ret;
  }

  export const palette = ["#FF8300", "#003966", "#FFD000", "#00BAB5", "#B3B3B3", "#D4E9FF"];

  export function randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;

    /* 
        Example Use 
            randomInt(4,10);
    */
}

export const reasons = [
  "Limited opportunities to education",
  "Difficulty to get into formal jobs", 
  "Lack of access to social security and health services",
  "Social beliefs about women's role as caregivers",
  "Teenage pregnancies"
];

