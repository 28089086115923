import ch1_1 from './resources/chapters/1/img1.jpg'
import ch1_2 from './resources/chapters/1/img2.jpg'
import ch1_3 from './resources/chapters/1/img3.jpg'

import ch2_1 from './resources/chapters/2/img1.jpg'
import ch2_2 from './resources/chapters/2/img2.jpg'
import ch2_3 from './resources/chapters/2/img3.jpg'

import ch3_1 from './resources/chapters/3/img1.jpg'

export const collaborators = [{
    name: 'TechSoup Global Network',
    link: 'https://www.techsoup.org/',
},{
    name: 'CEMEFI',
    link: 'https://www.cemefi.org/',
},{
    name: 'Plotree Info Design',
    link: 'https://plotree.studio/',
}];

export const chapterList = [{
    title: 'Food Insecurity in Mexican Women',
    handler: '/ch1',
},{
    title: 'Food Insecurity & Gender-based Violence',
    handler: '/ch2',
},{
    title: 'Hunger is a Result of Inequity',
    handler: '/ch3',
},{
    title: 'Project Title',
    handler: '/',
},{
    title: 'About the project',
    handler: () => window.open('https://docs.google.com/document/u/1/d/16qhk2rA2buYgAgq7cXNWOvXTqfjmTjARf5nuXE2RKMg'),
}];

export const ch1Slider = [{
    img: ch1_1,
    caption: ''
},{
    img: ch1_2,
    caption: ''
},{
    img: ch1_3,
    caption: ''
}];

export const ch2Slider = [{
    img: ch2_1,
    caption: ''
},{
    img: ch2_2,
    caption: ''
},{
    img: ch2_3,
    caption: ''
}];

export const ch3Slider = [{
    img: ch3_1,
    caption: ''
},{
    img: ch1_3,
    caption: ''
},{
    img: ch2_1,
    caption: ''
}];

export const ch1_desc_line = [
    <>
        <h3>Illustrating food insecurity</h3>
        <p>This chart will illustrate data between 2015-2020 to illustrate the extent of food insecurity in Mexico and its increasing gender gap.</p>
    </>,
    <>
        <h3>Rising food insecurity</h3>
        <p>The rate of severe food insecurity among women 15 years and older saw a steep rise between 2016 and 2018 before decreasing.</p>
    </>,
    <>
        <h3>Increasing Gender Gap</h3>
        <p>If we include persons facing moderate food insecurity, the data shows the <b>gender gap increased considerably between 2015 and 2020</b>. The difference in the rate of severe food insecurity among men and women was 3% in 2020.</p>
    </>,
    <>
        <h3>Moderate food insecurity has a larger gender gap</h3>
        <p>If we include persons facing moderate food insecurity, it is seen that the <b>gender gap has considerably increased between 2015 and 2020.</b></p></>,
        <><p>In 2020, the <b>gender gap was 12.3 percentage points</b> for moderate or severe food insecurity among women and men aged 15 years and older.</p></>,
];

export const ch1_desc_map = [
    <><p>According to CONEVAL, <b>approximately 20.7% of the Mexican female population is subject to moderate or severe food insecurity</b>. This equates to <span className='g-bold-i'>approximately 13.6 million women</span> in Mexico who face a lack of access to adequate and quality food.</p></>,
    <>
        <p>The rate of food insecurity in females varies geographically throughout the country with women in southern Mexico the most disadvantaged: <b>the rate of food insecurity among women in the south is 26.5% (5.8 percentage points higher than the national average in females).</b></p>
        <p className='second_para'>Approximately four million women in the south face food insecurity, meaning one in four women in this region cannot satisfy their food needs.</p>
    </>,
    <><p>Exploring food insecurity by state further illustrates that the south is more disadvantaged in terms of food insecurity.</p></>,
    <>
        <h3>Comparing food insecurity with population</h3>
        <p>Now we factor population into the equation to explore the relationship between the rate of food insecurity in a state and its population.</p>
    </>,
    <>
        <h3>Younger population is most disadvantaged</h3>
        <p>The data further suggests that the rate of food insecurity among females also varies across age groups with the <b>younger population (0-17 years) the most disadvantaged.</b></p>
        <p className='second_para'>Irrespective of region, around 25% of the total population from the age of 0 to 17 is reported as food insecure. <b>This is 5 percentage  points higher than the national average.</b></p>
    </>,
    <><p>The rest of the female population shows a lower rate of food insecurity compared to children aged 0 to 17.</p></>,
];

export const states = [
    'National',
    'Aguascalientes',
    'Baja California',
    'Baja California Sur',
    'Campeche',
    'Chiapas',
    'Chihuahua',
    'Ciudad de México',
    'Coahuila',
    'Colima',
    'Durango',
    'Estado de México',
    'Guanajuato',
    'Guerrero',
    'Hidalgo',
    'Jalisco',
    'Michoacán',
    'Morelos',
    'Nayarit',
    'Nuevo León',
    'Oaxaca',
    'Puebla',
    'Querétaro',
    'Quintana Roo',
    'San Luis Potosí',
    'Sinaloa',
    'Sonora',
    'Tabasco',
    'Tamaulipas',
    'Tlaxcala',
    'Veracruz',
    'Yucatán',
    'Zacatecas'
]