import { useRef, useEffect, useState } from "react";
import { appendMapCanvas, removeMapCanvas } from "./d3-functions/mapCanvas";
import { appendRegionPaths, appendRegionPoints } from "./d3-functions/mapParts";
import mapAnimFrames from "./d3-functions/mapAnimFrames";
import { mexicoTopoFeatures } from "./data-js-files/mexicoTopoFeatures";
import runSimulation from "./d3-functions/forceSim";
import {  scaleLinear, scaleSqrt } from "d3";
import { appendDiscColorLegend, appendCircLegend } from "./d3-functions/legends";

export default function MapFrames({ state }) {
  const [vizSelections, setVizSelections] = useState(null);

  const appRef = useRef();

  useEffect(() => {
    const mapCanvas = appendMapCanvas({
      parentRef: appRef.current,
      containerWidth: "100%",
      viewBoxWidth: 600,
      viewBoxHeight: 600,
      topoFeatures: mexicoTopoFeatures,
    });

    const colorScale = scaleLinear().domain([10, 30]).range(['#D4E9FF', '#003966']);

    const mapPaths = appendRegionPaths(
      mapCanvas,
      {
        groupId: "mexicoRegionsG",
      },
      {
        fill: colorScale(20.7),
        stroke: "white",
        "stroke-width": "1px",
        "fill-opacity": 1,
      }
    );

    appendDiscColorLegend(mapCanvas, {
      positionX: 5,
      positionY: 60,
      colorScaleFunc: scaleLinear().domain([10, 30]).range(['#D4E9FF', '#003966']),
      legendTitle: 'Food Insecurity (%)',
      scaleVal: 0.7
    });

    const circLegend = appendCircLegend(mapCanvas, {
      positionX: 450,
      positionY: 50,
      inputArr: [410000, 9250000],
      radScaleFunc: scaleSqrt().domain([404274, 9284275]).range([5, 23]),
      legendTitle: 'Population',
      scaleVal: 1
    });

    circLegend.style("opacity", 0);

    runSimulation(mexicoTopoFeatures);

    const mapPoints = appendRegionPoints(
      mapCanvas,
      {
        groupId: "mexicoPointsG",
      },
      {
        fill: "teal",
        "fill-opacity": 1,
      }
    );

    setVizSelections({ mapPaths, mapPoints, circLegend });

    return () => removeMapCanvas(appRef.current);
  }, []);

  useEffect(() => {
    if (vizSelections) {
      const frame = `frame${state}`;

      if (state >= 0) {
        mapAnimFrames[frame](vizSelections);
      }
    }
  }, [state]);

  return (
    <>
      <div id="map-story">
        <div className="vizContain" ref={appRef}></div>
      </div>
    </>
  );
}
