import styles from '../../css-modules/footer.module.css';
import layout from '../../css-modules/layouts.module.css'
import SplitScreen from '../layout/splitscreen';
import { collaborators } from '../../data';
import { useContext } from "react";
import { AppContext } from "../contexts";
import Footer from './footer';
import { applyClasses } from '../../utilities';
import Powered from './poweredBy';

export default function RenderFooter() {
    const { logo } = useContext(AppContext);
    const { logoGoogleWhite } = useContext(AppContext);

    return (
        <Footer>
            <SplitScreen>
                <div className={styles.logos}>
                    <img style={{cursor: 'pointer'}} onClick={() => window.open('https://www.tsgn.org/en')} src={logo} alt="" className={styles.logo}/>
                </div>
                <div className={applyClasses(layout, ['flexed-column', 'justify-end', 'grow', 'collab'])}>
                        <b>Collaborators</b>
                </div>
            </SplitScreen>

            <SplitScreen>
                <SplitScreen leftWeight={1} rightWeight={1.5}>
                <div className={styles.content}>
                        <div onClick={() => window.open('https://www.techsoup.org/privacypolicy')} className={styles.hover}><p>Privacy Policy</p></div>
                    </div>
                    <div className={styles.content}>
                        <div onClick={() => window.open('https://www.techsoup.org/termsofuse')} className={styles.hover}><p>Terms of Use</p></div>
                    </div>
                </SplitScreen>
                <div className={applyClasses(styles, ['content', 'participants'])}>
                    <div className={applyClasses(layout, ['flexed-column', 'justify-end', 'grow', 'collab_mob'])}>
                            <b>Collaborators</b>
                    </div>
                    {collaborators.map((d, i) => {
                        return <div><div key={d + 'footer' + i} className={styles.hover} onClick={() => window.open(d.link)}><p>{d.name}</p></div></div>
                    })}
                </div>
            </SplitScreen>
            <div style={{marginTop: '20px'}}>
                <div className={styles.googleLogoC} style={{cursor: 'pointer'}} onClick={() => window.open('https://www.google.org/')}>
                    <p className={styles.georgia} style={{width: 'max-content'}}>Funded By</p>
                    <img alt='' src={logoGoogleWhite} className={styles.googleLogo}/>
                </div>
                <Powered align='flex-start'/>
            </div>
        </Footer>
    )
}