import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MdArrowRightAlt } from "react-icons/md";
import SplitScreen from '../layout/splitscreen';
import { useNavigate } from 'react-router-dom';

function ChapterButton({
    image,
    title,
    subtitle,
    direction,
    handler
}) {

    const navigate = useNavigate();

    const clickHandler = (prop) => {
        if (typeof(prop) === 'string') {
            navigate(prop);
        }
        else {
            handler();
        }
    }

    return (
        <Container
            onClick={() => clickHandler(handler)}
            $image={image}
            $title={title}
            $radius={direction === 'forward' ? '0px 15px 15px 0px;' : '15px 0px 0px 15px;'}
        >
            <img src={image}/>
            <div style={{position: 'relative', zIndex: '3'}}>
                <SplitScreen leftWeight={8} rightWeight={1}>
                    <div>
                        <b>{title}</b>
                        <p>{subtitle}</p>
                    </div>
                    <ArrowContainer
                        $rotate={direction !== 'forward' ? '0deg' : '180deg'}
                        $align={direction !== 'forward' ? 'flex-end' : 'flex-start'}
                    >
                        <MdArrowRightAlt/>
                    </ArrowContainer>
                </SplitScreen>
            </div>
        </Container>
    );
}

ChapterButton.propTypes = {
    // By default all props are optional. Please add the isRequired bool if you need them to be mandatory.
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(["forward", "backward"]).isRequired,
    handler: PropTypes.oneOf([PropTypes.func, PropTypes.string]).isRequired
}

ChapterButton.defaultProps = {
    image: 'https://picsum.photos/600/400',
    title: 'Next Chapter',
    subtitle: 'Chapter Title Here',
    direction: 'forward',
    handler: defaultHandler
}

function defaultHandler() {
    console.log('button was clicked');
}

const Container = styled.div`
    background-image: ${props => "url(" + props.$image + ")"};
    background-size: cover;
    position: relative;
    color: white;
    border-radius: ${props => props.$radius};
    box-sizing: border-box;
    padding: 25px 35px;
    min-height: 325px;
    overflow: hidden;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2;
    }

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: 1;
        object-fit: cover;
        transition: 0.6s cubic-bezier(0.65, 0, 0.35, 1);
    }

    p {
        color: white !important;
        margin-top: 5px !important;
    }

    &:hover img {
        transform: scale(1.05);
    }

    @media (max-width: 768px) {
    ${props => props.$title === 'Next Chapter' ? "margin-left: 15vw;" : "margin-right: 15vw;"}
    }
`;

const ArrowContainer = styled.div`
    display: flex;
    justify-content: ${props => props.$align};
    transform: rotate(${props => props.$rotate});
    font-size: 30px;
`

export default ChapterButton;