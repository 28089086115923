import styled from 'styled-components';
import ReactIntersectionObserver from "./intersectionObserver";
import PropTypes from 'prop-types';

const Container = styled.div`
    position: relative;
    margin: ${props => props.$margin};
`;

function IO_List({ data, element: Element, margin, callback, fill, source }) {
    return (
        data.map((data, index) => {
            return (
                <Container key={index + 'io'} $margin={margin}>
                    <ReactIntersectionObserver key={index} callback={() => callback(index)}>
                        <Element {...{data, fill, source}}/>
                    </ReactIntersectionObserver>
                </Container>
            )
        })
    );
}

IO_List.propTypes = {
    data: PropTypes.arrayOf(PropTypes.node).isRequired,
    margin: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired,
}

IO_List.defaultProps = {
    margin: '425px 0px',
    callback: function () { console.log('callback' )}
}

export default IO_List;