import styled from 'styled-components';
import layout from '../../css-modules/layouts.module.css'
import SplitScreen from "../layout/splitscreen";
import { NavContainer } from '../wrappers';
import { useContext } from 'react';
import { AppContext } from '../contexts';
import { HiMenuAlt3 } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import { applyClasses, device } from '../../utilities';
import { useNavigate } from 'react-router-dom';

const Logo = styled.img.attrs(props => ({
    src: props.$menu ? props.$logo : props.$logoBlack,
}))`
    width: 150px;
    cursor: ${(props) => props.$menu ? 'auto' : 'pointer'};
`;

const Container = styled.div`
    position: relative;
    margin: 20px 0px;
    font-size: 1.5em;
    color: ${props => props.$menu ? '#fff' : '#000'};
    z-index: ${props => props.$menu ? 6 : 2}
`

function customNavigate( state, navigate ) {
    if (!state) {
        navigate('/');
    }
}

function Navbar({
    menu
}) {

    const navigate = useNavigate();
    const { logo, logoBlack, setMenuOpen } = useContext(AppContext);

    return (
        <Container $menu={menu}>
            <NavContainer>
                <SplitScreen breakpoint={device.never}>
                    <Logo $menu={menu} $logo={logo} $logoBlack={logoBlack} onClick={() => window.open('https://www.tsgn.org/en')}/>
                    <div className={applyClasses(layout, ['flexed-column', 'justify-center', 'grow', 'align-end'])}>
                        {!menu ?
                            <HiMenuAlt3 onClick={() => setMenuOpen(true)} className={'cursor'}/>
                        :
                            <MdClose onClick={() => setMenuOpen(false)} className={'cursor'}/>
                        }
                    </div>
                </SplitScreen>
            </NavContainer>
        </Container>
    )
};

export default Navbar;
