import styled from 'styled-components';
import Navbar from '../../general/navbar';
import Circle from './circle';
import styles from './css-modules/landing.module.css';
import ColorMap from './colorMap';
import { applyClasses } from '../../../utilities';
import { NarrowContent } from '../../wrappers';
import Title from './title';
import SplitScreen from '../../layout/splitscreen';
import Button from '../../general/button';
import { device } from '../../../utilities';

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;

    @media screen and (min-width: 500px) {
        min-height: 650px;
    }
    @media screen and (max-width: 500px) and (orientation: portrait) {
        height: ${window.innerHeight}px;
    }
    @media screen and (min-width: 500px) and (orientation: portrait) {
        height: ${window.innerHeight}px;
    }
    background: #fff;
    overflow: hidden;
`;

const Circles = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

function RenderCircles() {
    return (
        <Circles>
            <div id={styles.topCircle} className={applyClasses(styles, ['smallCircle', 'circle'])}>
                <Circle strokeColor={'var(--orange)'}/>
            </div>
            <div id={styles.rightCircle} className={applyClasses(styles, ['smallCircle', 'circle'])}>
                <Circle strokeColor={'var(--orange)'}/>
            </div>
            <div id={styles.leftCircle} className={applyClasses(styles, ['bigCircle', 'circle'])}>
                <Circle strokeColor={'var(--yellow)'} divider={65}/>
            </div>
        </Circles>
    );

}

const DescContainer = styled.div`

    margin-top: 1em;
    margin-right: 0;
    color: var(--textColor);
    width: calc(160px + 23vw);

    .desc {
        margin-top: 1em;
        margin-bottom: 1.5em;
        line-height: 1.5em;
        font-size: clamp(0.938rem, 0.875rem + 0.278vw, 1.125rem);
        width: clamp(350px, 20vw, 600px);
        padding-right: 1em;
    }

    @media (max-width: 955px) {
        .desc{
            margin-top: -4.8em;
        }
    }

    @media (max-width: 780px) {
        width: 200px;

        .desc{
            display: none;
        }
    }

    @media (min-width: 1280px) and (max-height: 640px) and (orientation: landscape) {
        .desc {
            width: 425px;
            font-size: 0.9em;
        }
        width: unset;
    }

`

function Landing( { title, desc }) {
    return (
        <Container>
            <Navbar/>
            <div style={{position: 'relative', zIndex: 1, height: '100%'}}>
                <RenderCircles/>
                <div className={styles.mapContainer}>
                    <ColorMap />
                </div>
                <NarrowContent>
                    <div className={styles.contentContainer}>
                        <Title {...{title}}/>
                        <DescContainer>
                            <p className='desc'>{desc}</p>
                            <SplitScreen breakpoint={device.laptop}>
                                <Button handler={'/ch1'} text='Explore the Data Story'/>
                                <Button handler={() => window.open('https://docs.google.com/document/d/16qhk2rA2buYgAgq7cXNWOvXTqfjmTjARf5nuXE2RKMg')} text='About the Project' theme="secondary"/>
                            </SplitScreen>
                        </DescContainer>
                    </div>
                </NarrowContent>
            </div>
        </Container>
    );
  }

  export default Landing;
