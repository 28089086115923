import { useEffect, useRef, useState } from "react";
import { removeChart } from "./d3-functions/chart";
import corrLineChartSetup from "./d3-functions/corrLineSetUp";
import styled from 'styled-components';

const Container = styled.div`
    position: relative;
`;

const LegendCont = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    width: 100%;
`

const LegendItem = styled.div`
    border-bottom: 4px solid ${(props) => props.$bg};
    padding: 5px;
    font-size: 0.78em;
    font-family: 'Georgia-Italic', serif;

    @media screen and (max-width: 500px) {
        font-size: 0.6em;
        border-bottom: 3px solid ${(props) => props.$bg};
    }
`;




const CorrLine = ({ state }) => {
    const container = useRef();

    const [visSelections, setVisSelections] = useState({});
    
    useEffect(() => {
        const visualSetup = corrLineChartSetup({parentRef: container.current});
        setVisSelections(visualSetup);
        return () => removeChart(container.current);
    }, []);

    useEffect(() => {
        const violence = 'avgViolence_' + state;
        const hunger = 'avgHunger_' + state; 

        const { lineViol, lineHunger } = visSelections;

        
        lineViol && lineViol.update({
            updYVarName: violence
        });
        lineHunger&& lineHunger.update({
            updYVarName: hunger
        });
    }, [state]);
    
    return (
        <Container>
            <div ref={container} id='corr_container'>
            </div>

            <LegendCont>
                <LegendItem $bg={'#D16C00'}><span>Average food insecurity</span></LegendItem>
                <LegendItem $bg={'#003966'}><span>Average gender-based violence</span></LegendItem>
            </LegendCont>
        </Container>
    )
}

export default CorrLine;