import { useRef, useEffect, useState } from "react";
import lineChartSetup from "./d3-functions/lineChartSetup";
import {
  removeChart,
} from "./d3-functions/chart";

import lineAnimFrames from "./d3-functions/lineAnimFrames";

export default function LineFrames({ state }) {
  const [vizSelections, setVizSelections] = useState(null);

  const appRef = useRef();

  useEffect(() => {
    const vizSelObj = lineChartSetup({parentRef: appRef.current});
    setVizSelections(vizSelObj);
    return () => removeChart(appRef.current);
  }, []);

  useEffect(() => {
    if (vizSelections) {
      const frame = `frame${state}`;
      if (state >= 0) {
        lineAnimFrames[frame](vizSelections);
      }
    }
  }, [state]);

  return (
    <>
      <div id="line-story-1">
        <div className="vizContain" ref={appRef}></div>
      </div>
    </>
  );
}
