export const mexicoTopoFeatures = [
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Aguascalientes",
      ADM1_PCODE: "MX01",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 3.99348278519,
      Shape_Area: 0.49115145173,
      Region: "Center",
      State: "Aguascalientes",
      women_0_17: 209725,
      fi_women_0_17: 48061,
      perc_fi_women_0_17: 22.9,
      women_18_64: 445899,
      fi_women_18_64: 73041,
      perc_fi_women_18_64: 16.4,
      women_65_plus: 61126,
      fi_women_65_plus: 7268,
      perc_fi_women_65_plus: 11.9,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-101.86186323907782, 22.028457268150063],
          [-101.88057920360306, 21.909677615485066],
          [-102.03810523835718, 21.852487412350065],
          [-102.116088423879, 21.736640590615064],
          [-102.32508336107753, 21.622260184345066],
          [-102.63389677574399, 21.767435315380062],
          [-102.744632899185, 21.720510020500065],
          [-102.87096565973037, 21.846621750490065],
          [-102.64169509429618, 22.227889771390064],
          [-102.46545309501683, 22.349602254985065],
          [-102.32196403365666, 22.389195472540063],
          [-102.03654557464674, 22.298277713710064],
          [-101.96792037138752, 22.110576534190066],
          [-101.86186323907782, 22.028457268150063],
        ],
      ],
    },
    centerX: 284.120685399672,
    centerY: 334.7521772855861,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Baja California",
      ADM1_PCODE: "MX02",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 29.4149825124,
      Shape_Area: 6.85131789647,
      Region: "North",
      State: "Baja California",
      women_0_17: 512765,
      fi_women_0_17: 79958,
      perc_fi_women_0_17: 15.6,
      women_18_64: 1193459,
      fi_women_18_64: 147561,
      perc_fi_women_18_64: 12.4,
      women_65_plus: 174173,
      fi_women_65_plus: 13433,
      perc_fi_women_65_plus: 7.7,
    },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-113.51255115604008, 29.54237011081006],
            [-113.38465873178426, 29.442653859190063],
            [-113.37062175839033, 29.310676467340063],
            [-113.18190244942748, 29.28721381990006],
            [-113.24428899784496, 29.067251500150064],
            [-113.59521333269322, 29.439721028260063],
            [-113.51255115604008, 29.54237011081006],
          ],
        ],
        [
          [
            [-114.81331069054428, 32.49426444185506],
            [-114.93496445995835, 32.48253311813506],
            [-114.97551571642971, 32.17751870141506],
            [-114.96459807045665, 31.917963164110063],
            [-114.80863169941298, 31.813847666095064],
            [-114.78367708004599, 31.63494497936506],
            [-114.88037623009306, 31.39298642764006],
            [-114.89129387606613, 31.158359953240065],
            [-114.82110900909647, 30.986789343835063],
            [-114.72129053162853, 30.93693121802506],
            [-114.69945523968241, 30.63484963223506],
            [-114.63083003642319, 30.41928655888006],
            [-114.66826196547368, 30.19785782366506],
            [-114.56376449687441, 30.010156644145063],
            [-114.28302502899581, 29.76966450788506],
            [-113.773014995683, 29.408926303495065],
            [-113.637324252875, 29.28428098897006],
            [-113.64356290771676, 29.184564737350062],
            [-113.37062175839033, 28.837024272145065],
            [-113.25832597123889, 28.839957103075065],
            [-113.13511253811438, 28.641991015300064],
            [-113.10235960019521, 28.504147961590064],
            [-112.90272264525932, 28.474819652290062],
            [-112.87308903476102, 28.279786395445065],
            [-112.79354618552874, 28.190335052080062],
            [-112.76547223874088, 27.99970104163006],
            [-114.06311244582423, 27.99970104163006],
            [-114.12237966682082, 28.260722994400062],
            [-114.04439648129899, 28.458689082175063],
            [-114.17852756039655, 28.653722339020064],
            [-114.3422922499924, 28.749039344245062],
            [-114.40779812583074, 28.869285412375064],
            [-114.59651743479358, 28.97633374132006],
            [-114.95056109706272, 29.36200100861506],
            [-115.18918964475954, 29.42798970454006],
            [-115.23597955607264, 29.501310477790064],
            [-115.49956272313644, 29.615690884060065],
            [-115.69764001436191, 29.75500035323506],
            [-115.70075934178277, 29.89870906880506],
            [-115.80525681038203, 29.97349625752006],
            [-115.80213748296116, 30.279977089705064],
            [-116.04700468549973, 30.47061110015506],
            [-116.05168367663104, 30.780024763270063],
            [-116.2669172686713, 30.96479311186006],
            [-116.31058785256353, 31.156893537775062],
            [-116.52114245347249, 31.435512476125062],
            [-116.65215420514917, 31.52203098856006],
            [-116.62096093094044, 31.857840130045062],
            [-116.87986510687293, 32.017679415730065],
            [-116.93445333673822, 32.24057456641006],
            [-117.02491383194355, 32.26990287571006],
            [-117.12317264570106, 32.53385765941006],
            [-116.1858147557286, 32.61157767905506],
            [-114.71973086791809, 32.718626008000065],
            [-114.81331069054428, 32.49426444185506],
          ],
        ],
      ],
    },
    centerX: 61.71155982380638,
    centerY: 167.2623880590522,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Baja California Sur",
      ADM1_PCODE: "MX03",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 51.7833534023,
      Shape_Area: 6.48602024635,
      Region: "North",
      State: "Baja California Sur",
      women_0_17: 121114,
      fi_women_0_17: 29197,
      perc_fi_women_0_17: 24.1,
      women_18_64: 286575,
      fi_women_18_64: 61151,
      perc_fi_women_18_64: 21.3,
      women_65_plus: 32634,
      fi_women_65_plus: 7201,
      perc_fi_women_65_plus: 22.1,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-112.76547223874088, 27.99970104163006],
          [-112.77171089358264, 27.86185798792006],
          [-112.59546889430328, 27.650694160960064],
          [-112.37243698371083, 27.562709233060062],
          [-112.30381178045161, 27.385272961795064],
          [-112.15720339167056, 27.16091139565006],
          [-112.00903533917908, 27.115452516235063],
          [-112.01059500288952, 26.961478892410064],
          [-111.87490426008152, 26.833900746955063],
          [-111.79536141084925, 26.883758872765064],
          [-111.56141185428375, 26.697524108710063],
          [-111.57076983654636, 26.572878794185065],
          [-111.47095135907841, 26.420371585825063],
          [-111.31966397916605, 26.077230367015062],
          [-111.35709590821654, 25.968715622605064],
          [-111.30718666948256, 25.773682365760063],
          [-110.94690435237167, 25.31322790975006],
          [-110.90791275961075, 25.146056546740063],
          [-110.65680690223044, 24.805848158860066],
          [-110.73946907888359, 24.624012641200064],
          [-110.68176152159744, 24.361524272965063],
          [-110.56322707960425, 24.213416311000064],
          [-110.30432290367175, 24.175289508910062],
          [-110.33239685045962, 24.329263132735065],
          [-110.00798679868878, 24.159158938795066],
          [-109.98615150674266, 24.04624494799006],
          [-109.82706580827812, 24.060909102640064],
          [-109.830185135699, 23.915733971605064],
          [-109.6991733840223, 23.798420734405063],
          [-109.67733809207618, 23.645913526045064],
          [-109.51981205732208, 23.584324076515063],
          [-109.42467257098545, 23.450880269200063],
          [-109.43870954437938, 23.233850780380063],
          [-109.57595995089781, 23.084276402950064],
          [-109.66953977352401, 23.060813755510065],
          [-109.85669941877642, 22.898041638895066],
          [-109.96431621479655, 22.871646160525064],
          [-110.10000695760453, 23.022686953420063],
          [-110.16395316973245, 23.307171553630063],
          [-110.31368088593437, 23.544730858960065],
          [-110.68488084901831, 23.770558840570065],
          [-111.02800686531438, 24.097569489265062],
          [-111.60820176559685, 24.436311461680063],
          [-111.65655134062038, 24.569755268995063],
          [-111.79380174713882, 24.546292621555065],
          [-111.92169417139462, 24.731060970145066],
          [-112.11665213519922, 24.791184004210063],
          [-112.18215801103756, 24.96275461361506],
          [-112.08233953356961, 25.295630924170062],
          [-112.07298155130698, 25.673966114140065],
          [-112.1883966658793, 25.980446946325063],
          [-112.30849077158292, 26.072831120620066],
          [-112.40674958534044, 26.24440173002506],
          [-112.49721008054577, 26.231203990840065],
          [-113.06180834372385, 26.599274272555064],
          [-113.19437975911097, 26.79577394486506],
          [-113.42832931567649, 26.81043809951506],
          [-113.54686375766968, 26.719520340685065],
          [-113.71686710210727, 26.791374698470065],
          [-113.87127380944051, 26.979075877990063],
          [-114.00228556111719, 26.971743800665063],
          [-114.13017798537301, 27.115452516235063],
          [-114.47954265651083, 27.21810159878506],
          [-114.50761660329869, 27.408735609235062],
          [-114.73844683244333, 27.524582430970064],
          [-114.77899808891468, 27.600836035150063],
          [-115.01450730919062, 27.740145504325064],
          [-114.9240468139853, 27.833996094085062],
          [-114.60587541705621, 27.773873060020065],
          [-114.38128384275332, 27.806134200250064],
          [-114.10834269342689, 27.612567358870063],
          [-114.20504184347396, 27.99970104163006],
          [-114.14889394989825, 27.99970104163006],
          [-114.09742504745384, 27.99970104163006],
          [-114.08650740148077, 27.99970104163006],
          [-114.06311244582423, 27.99970104163006],
          [-112.76547223874088, 27.99970104163006],
        ],
      ],
    },
    centerX: 114.38713883943902,
    centerY: 259.04095998538367,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Campeche",
      ADM1_PCODE: "MX04",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 27.8334077023,
      Shape_Area: 4.75784452435,
      Region: "South",
      State: "Campeche",
      women_0_17: 140742,
      fi_women_0_17: 35750,
      perc_fi_women_0_17: 25.4,
      women_18_64: 321373,
      fi_women_18_64: 76871,
      perc_fi_women_18_64: 23.9,
      women_65_plus: 39646,
      fi_women_65_plus: 7805,
      perc_fi_women_65_plus: 19.7,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-89.29721238779966, 19.551681547765064],
          [-89.1459250078873, 19.424103402310067],
          [-89.14280568046642, 18.145389116830067],
          [-89.15216366272905, 17.940090951730063],
          [-89.15216366272905, 17.815445637205066],
          [-90.28603918021653, 17.815445637205066],
          [-90.98788784991305, 17.815445637205066],
          [-90.98788784991305, 17.962087183705066],
          [-91.21715841534726, 17.975284922890065],
          [-91.55248611309115, 18.158586856015067],
          [-91.6101936703773, 18.095530991020066],
          [-91.63826761716517, 17.874102255805067],
          [-91.96579699635689, 18.016344555910067],
          [-92.12176336740055, 18.102863068345066],
          [-92.17947092468671, 18.268568015890065],
          [-92.17635159726584, 18.457735610875066],
          [-92.33075830459907, 18.460668441805065],
          [-92.4087414901209, 18.484131089245064],
          [-92.46644904740707, 18.651302452255067],
          [-91.95643901409426, 18.696761331670064],
          [-91.75056340431661, 18.453336364480066],
          [-91.56652308648508, 18.441605040760066],
          [-91.30917857426303, 18.558918277960064],
          [-91.23743404358294, 18.727556056435066],
          [-91.38248276865355, 18.825805892590065],
          [-91.33881218476132, 18.924055728745067],
          [-91.112660946748, 19.035503304085065],
          [-90.89898701841817, 19.186544096980064],
          [-90.72274501913881, 19.358114706385066],
          [-90.70714838203445, 19.676326862290065],
          [-90.45292319723326, 19.952012969710065],
          [-90.50283243596724, 20.497519522690066],
          [-90.38117866655318, 20.847992818825062],
          [-90.38741732139492, 20.556176141290067],
          [-90.0676862607554, 20.425665164905062],
          [-90.00374004862749, 20.487254614435066],
          [-89.59822748391394, 19.997471849125063],
          [-89.5873098379409, 19.874292950065065],
          [-89.4687753959477, 19.699789509730063],
          [-89.29721238779966, 19.551681547765064],
        ],
      ],
    },
    centerX: 494.21345506702016,
    centerY: 393.5585910849199,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Chiapas",
      ADM1_PCODE: "MX07",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 26.8350581546,
      Shape_Area: 6.15048828388,
      Region: "South",
      State: "Chiapas",
      women_0_17: 992840,
      fi_women_0_17: 219254,
      perc_fi_women_0_17: 22.1,
      women_18_64: 1688055,
      fi_women_18_64: 353611,
      perc_fi_women_18_64: 20.9,
      women_65_plus: 233909,
      fi_women_65_plus: 43717,
      perc_fi_women_65_plus: 18.7,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-93.27591451312372, 17.982617000215065],
          [-93.10435150497568, 17.907829811500065],
          [-92.97957840814074, 17.511897635950064],
          [-92.82985069193882, 17.479636495720065],
          [-92.7565464975483, 17.344726272940065],
          [-92.57094651600633, 17.550024438040065],
          [-92.43525577319832, 17.570554254550064],
          [-91.99075161572387, 17.919561135220064],
          [-91.78175667852534, 17.853572439295064],
          [-91.77863735110446, 17.706930892795064],
          [-91.65854324540085, 17.674669752565066],
          [-91.67258021879478, 17.444442524560067],
          [-91.50569620177805, 17.398983645145066],
          [-91.44019032593971, 17.250875683180066],
          [-91.26394832666035, 17.168756417140067],
          [-91.17504749516547, 17.016249208780067],
          [-90.71026770945532, 16.721499700315064],
          [-90.6416425061961, 16.513268704285064],
          [-90.41705093189321, 16.422350945455065],
          [-90.44200555126021, 16.074810480250065],
          [-91.73184743979137, 16.073344064785065],
          [-92.21066419889544, 15.260949897175067],
          [-92.07497345608745, 15.089379287770067],
          [-92.18259025210759, 14.847420736045066],
          [-92.14671798676754, 14.661185971990067],
          [-92.22938016342069, 14.535074242000066],
          [-92.51323895872017, 14.801961856630067],
          [-92.89535656777716, 15.197894032180066],
          [-93.33518173412031, 15.599691869590066],
          [-93.6954640512312, 15.865113068755065],
          [-93.90601865214015, 15.979493475025066],
          [-93.89822033358797, 16.083608973040064],
          [-94.10253627965517, 16.143732007105065],
          [-94.05106737721076, 16.329966771160066],
          [-94.13840854499522, 16.461944163010067],
          [-93.86702705937924, 17.136495276910065],
          [-93.77188757304259, 17.246476436785066],
          [-93.60812288344674, 17.315397963640066],
          [-93.48958844145355, 17.538293114320066],
          [-93.38197164543341, 17.645341443265064],
          [-93.29774980506983, 17.947423029055066],
          [-93.27591451312372, 17.982617000215065],
        ],
      ],
    },
    centerX: 456.6629416091819,
    centerY: 436.76241783527854,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Chihuahua",
      ADM1_PCODE: "MX08",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 30.0763652155,
      Shape_Area: 22.87354566,
      Region: "North",
      State: "Chihuahua",
      women_0_17: 565280,
      fi_women_0_17: 87604,
      perc_fi_women_0_17: 15.5,
      women_18_64: 1235135,
      fi_women_18_64: 146651,
      perc_fi_women_18_64: 11.9,
      women_65_plus: 181883,
      fi_women_65_plus: 18625,
      perc_fi_women_65_plus: 10.2,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-103.30767149865265, 29.00272921969006],
          [-103.51510677214074, 28.665453662740063],
          [-103.64299919639654, 28.512946454380064],
          [-103.86759077069944, 28.112615032435063],
          [-103.94245462880039, 27.92784668384506],
          [-103.85511346101595, 27.549511493875062],
          [-103.87694875296205, 27.311952188545064],
          [-103.74437733757493, 27.02306834194006],
          [-103.85199413359507, 26.895490196485063],
          [-103.69914708997227, 26.725386002545065],
          [-104.05631007966228, 26.748848649985064],
          [-104.31521425559477, 26.79870677579506],
          [-104.33081089269913, 26.656464475690065],
          [-104.4353083612984, 26.605139934415064],
          [-104.57411843152727, 26.341185150715063],
          [-104.71292850175614, 26.467296880705064],
          [-104.89852848329811, 26.509822929190065],
          [-105.0560545180522, 26.418905170360063],
          [-105.10128476565488, 26.521554252910065],
          [-105.26504945525073, 26.461431218845064],
          [-105.54890825055021, 26.546483315815063],
          [-106.04800063788996, 26.844165655210062],
          [-106.20864600006495, 26.678460707665064],
          [-106.24139893798412, 26.467296880705064],
          [-106.43323757436782, 26.203342097005063],
          [-106.45507286631394, 25.973114869000064],
          [-106.5595703349132, 25.912991834935063],
          [-106.55645100749233, 25.691563099720064],
          [-106.6531501575394, 25.578649108915066],
          [-106.87306274071098, 25.571317031590063],
          [-106.99471651012504, 25.662234790420065],
          [-107.05866272225295, 25.757551795645064],
          [-107.29573160623933, 25.924723158655063],
          [-107.30508958850196, 26.075763951550066],
          [-107.41582571194296, 26.141752647475066],
          [-107.79014500244777, 26.168148125845065],
          [-108.01317691304021, 26.43943498687006],
          [-107.96326767430625, 26.527419914770064],
          [-108.06464581548462, 26.647665982900065],
          [-108.00537859448804, 26.760579973705063],
          [-108.09427942598293, 26.87056113358006],
          [-108.22997016879093, 26.905755104740063],
          [-108.24244747847442, 27.042131742985063],
          [-108.50291131811734, 26.987874370780062],
          [-108.60272979558529, 27.036266081125063],
          [-108.66511634400277, 27.240097830760064],
          [-108.59181214961224, 27.317817850405063],
          [-108.76649448518116, 27.70641794863006],
          [-108.92090119251438, 27.78413796827506],
          [-108.92713984735613, 27.941044423030064],
          [-109.0316373159554, 28.018764442675064],
          [-109.07530789984762, 28.27245431812006],
          [-108.75089784807679, 28.270987902655065],
          [-108.48887434472341, 28.375103400670064],
          [-108.58401383106005, 28.740240851455063],
          [-108.68695163594887, 28.875151074235063],
          [-108.75401717549767, 29.20069530746506],
          [-108.66979533513407, 29.307743636410063],
          [-108.71814491015762, 29.466116506630062],
          [-108.54190291087826, 29.796059986255063],
          [-108.6074087867166, 29.84738452753006],
          [-108.6198860964001, 30.297574075285063],
          [-108.66199701658189, 30.521935641430062],
          [-108.87411128120128, 30.62311830851506],
          [-108.93961715703962, 30.90760290872506],
          [-108.8023667505212, 30.90467007779506],
          [-108.84603733441342, 31.13489730580006],
          [-108.75557683920809, 31.33286339357506],
          [-108.20813487684481, 31.33286339357506],
          [-108.20813487684481, 31.783052941330062],
          [-106.52837706070447, 31.78451935679506],
          [-106.38176867192342, 31.73172840005506],
          [-106.219563646038, 31.480971355540063],
          [-105.9559804789742, 31.36512453380506],
          [-105.77194016114267, 31.16569203056506],
          [-105.6034964804155, 31.08357276452506],
          [-105.39450154321698, 30.86214402931006],
          [-105.21825954393763, 30.806420241640062],
          [-104.86577554537894, 30.49407374759506],
          [-104.85953689053719, 30.38995824958006],
          [-104.6864142186787, 30.168529514365062],
          [-104.68329489125783, 29.929503793570063],
          [-104.54448482102897, 29.68167957998506],
          [-104.16860586681372, 29.395728564310062],
          [-103.97364790300914, 29.296012312690063],
          [-103.80988321341327, 29.27254966525006],
          [-103.30767149865265, 29.00272921969006],
        ],
      ],
    },
    centerX: 212.24653400725975,
    centerY: 202.59126185046495,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Coahuila de Zaragoza",
      ADM1_PCODE: "MX05",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 22.9630623344,
      Shape_Area: 13.8223366838,
      Region: "North",
      State: "Coahuila",
      women_0_17: 443116,
      fi_women_0_17: 80445,
      perc_fi_women_0_17: 18.2,
      women_18_64: 998969,
      fi_women_18_64: 146324,
      perc_fi_women_18_64: 14.6,
      women_65_plus: 150559,
      fi_women_65_plus: 17123,
      perc_fi_women_65_plus: 11.4,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-99.84365839777271, 27.769473813625062],
          [-99.99806510510595, 27.615500189800063],
          [-100.18834407777922, 27.79880212292506],
          [-100.35054910366465, 27.684421716655063],
          [-100.41761464321343, 27.394071454585063],
          [-100.67807848285636, 27.335414835985063],
          [-100.83404485390002, 27.194638951345063],
          [-100.83092552647915, 27.07439288321506],
          [-100.5392684126275, 27.047997404845063],
          [-100.54238774004835, 26.806038853120064],
          [-100.69055579253985, 26.613938427205063],
          [-100.75294234095732, 26.73565091080006],
          [-101.2068044806944, 26.369047044550065],
          [-101.04771878222985, 26.16961454131006],
          [-100.91670703055317, 26.075763951550066],
          [-100.90578938458012, 25.95698429888506],
          [-100.78101628774519, 25.638772142980063],
          [-100.59697596991364, 25.544921553220064],
          [-100.47064320936828, 25.338156972655064],
          [-100.25384995361757, 25.30736224789006],
          [-100.3318331391394, 25.185649764295064],
          [-100.59229697878234, 25.254571291150064],
          [-100.80285157969129, 25.165119947785065],
          [-100.82624653534785, 24.835176468160064],
          [-100.79973225227042, 24.556557529810064],
          [-100.95881795073497, 24.54335979062506],
          [-101.12726163146213, 24.624012641200064],
          [-101.20212548956309, 24.764788525840064],
          [-101.3424952235024, 24.81318023618506],
          [-101.46102966549559, 24.736926632005066],
          [-101.60139939943488, 24.835176468160064],
          [-101.77296240758292, 24.876236101180062],
          [-101.84938592939433, 25.017011985820062],
          [-102.23774219329307, 25.118194652905064],
          [-102.46389343130639, 25.115261821975064],
          [-102.44673713049158, 24.999415000240063],
          [-102.66976904108404, 25.066870111630063],
          [-102.85380935891557, 24.772120603165064],
          [-103.0971168977437, 24.826377975370065],
          [-103.25932192362912, 24.964221029080065],
          [-103.51042778100943, 25.289765262310063],
          [-103.3684983833597, 25.415876992300063],
          [-103.49327148019462, 25.53758947589506],
          [-103.36381939222838, 25.673966114140065],
          [-103.34978241883445, 26.141752647475066],
          [-103.26088158733955, 26.283994947580062],
          [-103.37473703820143, 26.602207103485064],
          [-103.69914708997227, 26.725386002545065],
          [-103.85199413359507, 26.895490196485063],
          [-103.74437733757493, 27.02306834194006],
          [-103.87694875296205, 27.311952188545064],
          [-103.85511346101595, 27.549511493875062],
          [-103.94245462880039, 27.92784668384506],
          [-103.86759077069944, 28.112615032435063],
          [-103.64299919639654, 28.512946454380064],
          [-103.51510677214074, 28.665453662740063],
          [-103.30767149865265, 29.00272921969006],
          [-103.15326479131942, 28.971934494925062],
          [-103.10023622516456, 29.05991942282506],
          [-102.87096565973037, 29.241754940485063],
          [-102.80857911131291, 29.523306709765063],
          [-102.69160433303016, 29.721272797540063],
          [-102.39058923691587, 29.782862247070064],
          [-102.34223966189234, 29.869380759505063],
          [-102.116088423879, 29.791660739860063],
          [-101.50470024938781, 29.76379884602506],
          [-101.25047506458662, 29.624489376850065],
          [-101.23799775490313, 29.523306709765063],
          [-101.06019609191335, 29.458784429305062],
          [-101.00404819833763, 29.36493383954506],
          [-100.79349359742868, 29.241754940485063],
          [-100.66404150946242, 29.07458357747506],
          [-100.63284823525369, 28.903012968070062],
          [-100.34119112140203, 28.448424173920063],
          [-100.29440121008892, 28.28418564184006],
          [-100.09008526402172, 28.147809003595064],
          [-99.89356763650669, 27.899984790010063],
          [-99.84365839777271, 27.769473813625062],
        ],
      ],
    },
    centerX: 289.53048842849864,
    centerY: 232.6427336542817,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Colima",
      ADM1_PCODE: "MX06",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 6.24277788334,
      Shape_Area: 0.494913356391,
      Region: "Center",
      State: "Colima",
      women_0_17: 105653,
      fi_women_0_17: 20205,
      perc_fi_women_0_17: 19.1,
      women_18_64: 254095,
      fi_women_18_64: 41013,
      perc_fi_women_18_64: 16.1,
      women_65_plus: 44526,
      fi_women_65_plus: 6795,
      perc_fi_women_65_plus: 15.3,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-103.48859248906331, 18.965115361765065],
          [-103.72722103676013, 18.702626993530064],
          [-103.98300588527175, 18.872731187470066],
          [-104.33393022012001, 19.023771980365066],
          [-104.36668315803918, 19.119088985590064],
          [-104.68953354609958, 19.176279188725065],
          [-104.68017556383697, 19.192409758840064],
          [-104.66301926302216, 19.183611266050065],
          [-104.52264952908286, 19.259864870230064],
          [-104.1888814950494, 19.302390918715066],
          [-104.06098907079358, 19.497424175560063],
          [-103.82080085938634, 19.410905663125064],
          [-103.61492524960869, 19.475427943585064],
          [-103.48703282535288, 19.337584889875064],
          [-103.52602441811379, 19.078029352570063],
          [-103.48859248906331, 18.965115361765065],
        ],
      ],
    },
    centerX: 256.8257218232124,
    centerY: 388.19160759234796,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Distrito Federal",
      ADM1_PCODE: "MX09",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 1.92070899147,
      Shape_Area: 0.128446710951,
      Region: "Center",
      State: "Ciudad de M√©xico",
      women_0_17: 838967,
      fi_women_0_17: 162966,
      perc_fi_women_0_17: 19.4,
      women_18_64: 3098642,
      fi_women_18_64: 491479,
      perc_fi_women_18_64: 15.9,
      women_65_plus: 654324,
      fi_women_65_plus: 62553,
      perc_fi_women_65_plus: 9.6,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-98.97960470219078, 19.075096521640063],
          [-99.05914755142305, 19.048701043270064],
          [-99.27906013459463, 19.132286724775064],
          [-99.36484163866865, 19.277461855810067],
          [-99.2229122410189, 19.429969064170066],
          [-99.06850553368567, 19.488625682770063],
          [-98.95776941024467, 19.322920735225065],
          [-98.97960470219078, 19.075096521640063],
        ],
      ],
    },
    centerX: 340.34494046722966,
    centerY: 385.95110262921156,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Durango",
      ADM1_PCODE: "MX10",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 23.7126055398,
      Shape_Area: 11.0268611141,
      Region: "North",
      State: "Durango",
      women_0_17: 291703,
      fi_women_0_17: 62609,
      perc_fi_women_0_17: 21.5,
      women_18_64: 562922,
      fi_women_18_64: 97512,
      perc_fi_women_18_64: 17.3,
      women_65_plus: 97509,
      fi_women_65_plus: 15003,
      perc_fi_women_65_plus: 15.4,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-103.69914708997227, 26.725386002545065],
          [-103.37473703820143, 26.602207103485064],
          [-103.26088158733955, 26.283994947580062],
          [-103.34978241883445, 26.141752647475066],
          [-103.36381939222838, 25.673966114140065],
          [-103.49327148019462, 25.53758947589506],
          [-103.3684983833597, 25.415876992300063],
          [-103.51042778100943, 25.289765262310063],
          [-103.25932192362912, 24.964221029080065],
          [-103.0971168977437, 24.826377975370065],
          [-102.85380935891557, 24.772120603165064],
          [-102.66976904108404, 25.066870111630063],
          [-102.56683123619521, 24.902631579550064],
          [-102.47949006841075, 24.490568833885064],
          [-102.53407829827604, 24.408449567845064],
          [-102.70408164271365, 24.374722012150066],
          [-103.04252866787841, 24.467106186445065],
          [-103.42932526806672, 24.411382398775064],
          [-103.55877735603296, 24.194352909955064],
          [-103.80988321341327, 24.04037928613006],
          [-103.81612186825502, 23.807219227195063],
          [-103.8831874078038, 23.591656153840063],
          [-104.0656680619249, 23.464078008385066],
          [-104.16548653939284, 22.942034102845064],
          [-104.34952685722438, 22.409725289050066],
          [-104.494575582295, 22.375997733355064],
          [-104.65522094446997, 22.462516245790063],
          [-104.77843437759448, 22.642885347985064],
          [-104.96091503171557, 22.505042294275064],
          [-105.07165115515657, 22.656083087170064],
          [-104.91568478411291, 22.748467261465066],
          [-105.01394359787042, 22.977228074005062],
          [-105.2978023931699, 22.953765426565063],
          [-105.39294187950654, 23.084276402950064],
          [-105.64560740059729, 23.144399437015064],
          [-105.7734998248531, 23.560861429075064],
          [-105.90919056766108, 23.588723322910063],
          [-105.9606594701055, 23.840946782890065],
          [-105.90919056766108, 23.983189082995064],
          [-106.07451492096739, 24.269140098670064],
          [-106.26947288477197, 24.374722012150066],
          [-106.36305270739818, 24.304334069830063],
          [-106.52837706070447, 24.295535577040063],
          [-106.70617872369425, 24.665072274220066],
          [-107.13040725293304, 25.053672372445064],
          [-107.12416859809129, 25.187116179760064],
          [-107.20995010216531, 25.417343407765063],
          [-106.99471651012504, 25.662234790420065],
          [-106.87306274071098, 25.571317031590063],
          [-106.6531501575394, 25.578649108915066],
          [-106.55645100749233, 25.691563099720064],
          [-106.5595703349132, 25.912991834935063],
          [-106.45507286631394, 25.973114869000064],
          [-106.43323757436782, 26.203342097005063],
          [-106.24139893798412, 26.467296880705064],
          [-106.20864600006495, 26.678460707665064],
          [-106.04800063788996, 26.844165655210062],
          [-105.54890825055021, 26.546483315815063],
          [-105.26504945525073, 26.461431218845064],
          [-105.10128476565488, 26.521554252910065],
          [-105.0560545180522, 26.418905170360063],
          [-104.89852848329811, 26.509822929190065],
          [-104.71292850175614, 26.467296880705064],
          [-104.57411843152727, 26.341185150715063],
          [-104.4353083612984, 26.605139934415064],
          [-104.33081089269913, 26.656464475690065],
          [-104.31521425559477, 26.79870677579506],
          [-104.05631007966228, 26.748848649985064],
          [-103.69914708997227, 26.725386002545065],
        ],
      ],
    },
    centerX: 239.47041476661238,
    centerY: 279.02818111814497,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Guanajuato",
      ADM1_PCODE: "MX11",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 12.8553268598,
      Shape_Area: 2.65728712826,
      Region: "Center",
      State: "Guanajuato",
      women_0_17: 893897,
      fi_women_0_17: 249736,
      perc_fi_women_0_17: 27.9,
      women_18_64: 1961132,
      fi_women_18_64: 419111,
      perc_fi_women_18_64: 21.4,
      women_65_plus: 284250,
      fi_women_65_plus: 46411,
      perc_fi_women_65_plus: 16.3,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-99.77659285822394, 21.494682038890065],
          [-99.73916092917345, 21.235126501585064],
          [-99.82962142437879, 21.157406481940065],
          [-99.9871474591329, 21.218995931470065],
          [-100.06357098094429, 20.935977746725065],
          [-100.47064320936828, 20.906649437425067],
          [-100.55954404086316, 20.830395833245063],
          [-100.49559782873526, 20.616299175355064],
          [-100.47688186421001, 20.389004778280064],
          [-100.35054910366465, 20.287822111195066],
          [-100.36302641334814, 19.990139771800067],
          [-100.66092218204156, 19.965210708895064],
          [-100.77945662403474, 19.915352583085067],
          [-100.9650566055767, 19.928550322270063],
          [-101.01652550802112, 20.085456777025065],
          [-101.20212548956309, 20.025333742960065],
          [-101.39396412594681, 20.053195636795063],
          [-101.45479101065384, 20.318616835960064],
          [-101.62011536396014, 20.315684005030064],
          [-101.69341955835066, 20.192505105970064],
          [-101.93984642459966, 20.227699077130065],
          [-101.9460850794414, 20.343545898865067],
          [-102.09581279564333, 20.384605531885065],
          [-101.96012205283533, 20.585504450590065],
          [-102.09581279564333, 20.783470538365066],
          [-101.64351031961668, 21.299648782045065],
          [-101.58892208975139, 21.465353729590063],
          [-101.64195065590624, 21.526943179120064],
          [-101.51093890422956, 21.799696455610064],
          [-101.49690193083563, 21.809961363865064],
          [-101.26139271055969, 21.823159103050063],
          [-101.16157423309174, 21.757170407125066],
          [-100.98221290639151, 21.754237576195063],
          [-100.85743980955658, 21.619327353415066],
          [-100.61569193443889, 21.506413362610065],
          [-100.46440455452652, 21.543073749235063],
          [-100.45192724484303, 21.666252648295064],
          [-100.27256591814282, 21.686782464805063],
          [-99.94191721153022, 21.462420898660064],
          [-99.77659285822394, 21.494682038890065],
        ],
      ],
    },
    centerX: 307.63658690689294,
    centerY: 355.5264694223357,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Guerrero",
      ADM1_PCODE: "MX12",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 20.3312788516,
      Shape_Area: 5.41043062352,
      Region: "South",
      State: "Guerrero",
      women_0_17: 609510,
      fi_women_0_17: 222600,
      perc_fi_women_0_17: 36.5,
      women_18_64: 1113476,
      fi_women_18_64: 365314,
      perc_fi_women_18_64: 32.8,
      women_65_plus: 188762,
      fi_women_65_plus: 52123,
      perc_fi_women_65_plus: 27.6,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-99.4942937266349, 18.720223979110067],
          [-99.35548365640602, 18.476799011920065],
          [-99.16052569260144, 18.465067688200065],
          [-99.06538620626479, 18.333090296350065],
          [-98.99988033042645, 18.190847996245065],
          [-98.88134588843326, 18.165918933340066],
          [-98.76749043757138, 17.989949077540064],
          [-98.65207532299907, 17.934225289870064],
          [-98.43372240353793, 17.984083415680065],
          [-98.4243644212753, 17.865303763015064],
          [-98.49454928824495, 17.772919588720065],
          [-98.46179635032578, 17.630677288615065],
          [-98.31206863412386, 17.478170080255065],
          [-98.32298628009693, 17.275804746085065],
          [-98.2200484752081, 17.126230368655065],
          [-98.05784344932268, 17.023581286105067],
          [-98.07188042271662, 16.875473324140067],
          [-98.20445183810372, 16.590988723930067],
          [-98.394730810777, 16.546996259980066],
          [-98.34950056317435, 16.444347177430064],
          [-98.55225684553112, 16.315302616510067],
          [-98.7721694287027, 16.554328337305066],
          [-98.90630050780025, 16.533798520795067],
          [-99.04978956916042, 16.598320801255067],
          [-99.43346684192787, 16.678973651830066],
          [-99.67365505333511, 16.700969883805065],
          [-99.96063317605547, 16.894536725185066],
          [-100.91514736684275, 17.221547373880064],
          [-101.20992380811528, 17.431244785375064],
          [-101.4313960549973, 17.522162544205067],
          [-101.64351031961668, 17.667337675240066],
          [-101.80883467292297, 17.887299994990066],
          [-102.17847497229647, 18.039807203350065],
          [-102.14104304324599, 18.187915165315065],
          [-101.97883801736057, 18.206978566360064],
          [-101.8649825664987, 18.286165001470064],
          [-101.87901953989262, 18.539854876915065],
          [-101.63883132848537, 18.602910741910065],
          [-101.50781957680869, 18.487063920175064],
          [-101.28166833879536, 18.544254123310065],
          [-100.92294568539492, 18.444537871690066],
          [-100.7435843586947, 18.517858644940066],
          [-100.79193393371824, 18.834604385380064],
          [-100.61257260701802, 18.887395342120065],
          [-100.43165161660735, 18.780347013175067],
          [-100.48779951018308, 18.652768867720063],
          [-100.25696928103844, 18.428407301575064],
          [-99.99026678655378, 18.569183186215064],
          [-99.79374915903874, 18.629306220280064],
          [-99.69705000899167, 18.786212675035067],
          [-99.4942937266349, 18.720223979110067],
        ],
      ],
    },
    centerX: 326.5011915041308,
    centerY: 415.1351780123458,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Hidalgo",
      ADM1_PCODE: "MX13",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 14.6311866708,
      Shape_Area: 1.80276251404,
      Region: "Center",
      State: "Hidalgo",
      women_0_17: 464685,
      fi_women_0_17: 139885,
      perc_fi_women_0_17: 30.1,
      women_18_64: 993832,
      fi_women_18_64: 242697,
      perc_fi_women_18_64: 24.4,
      women_65_plus: 153868,
      fi_women_65_plus: 27961,
      perc_fi_women_65_plus: 18.2,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-97.98453925493214, 20.516582923735065],
          [-98.1779375550263, 20.295154188520065],
          [-98.10775268805665, 20.120650748185064],
          [-98.26995771394208, 19.896289182040064],
          [-98.25592074054813, 19.718852910775066],
          [-98.38225350109352, 19.608871750900065],
          [-98.666112296393, 19.605938919970065],
          [-98.59748709313378, 19.765778205655064],
          [-98.77996774725487, 19.883091442855065],
          [-98.96556772879684, 19.891889935645064],
          [-98.9468517642716, 20.026800158425065],
          [-99.158966028891, 20.026800158425065],
          [-99.34300634672253, 19.780442360305067],
          [-99.48337608066183, 19.905087674830064],
          [-99.43658616934873, 20.000404680055063],
          [-99.5286063282645, 20.163176796670065],
          [-99.72980294691084, 20.171975289460065],
          [-99.85925503487708, 20.268758710150067],
          [-99.82806176066835, 20.542978402105064],
          [-99.50209204518708, 20.658825223840065],
          [-99.54732229278974, 20.727746750695065],
          [-99.38979625803563, 20.953574732305064],
          [-99.30869374509292, 21.147141573685065],
          [-99.21511392246671, 21.106081940665064],
          [-99.04355091431869, 21.179402713915064],
          [-99.05446856029174, 21.282051796465066],
          [-98.93281479087767, 21.298182366580065],
          [-98.82051900372623, 21.160339312870065],
          [-98.60840473910685, 21.201398945890062],
          [-98.50390727050758, 21.389100125410064],
          [-98.54133919955805, 21.268854057280066],
          [-98.41656610272312, 21.154473651010065],
          [-98.22784679376028, 21.150074404615065],
          [-98.14986360823845, 21.082619293225065],
          [-98.25124174941683, 20.897850944635064],
          [-98.44308038580054, 20.834795079640067],
          [-98.53198121729544, 20.716015426975066],
          [-98.47895265114059, 20.594302943380065],
          [-98.57877112860854, 20.478456121645063],
          [-98.46023668661535, 20.331814575145064],
          [-98.10619302434623, 20.616299175355064],
          [-97.98453925493214, 20.516582923735065],
        ],
      ],
    },
    centerX: 344.59037307031633,
    centerY: 363.50097382108095,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Jalisco",
      ADM1_PCODE: "MX14",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 34.751600327,
      Shape_Area: 6.80953768727,
      Region: "Center",
      State: "Jalisco",
      women_0_17: 1284309,
      fi_women_0_17: 235869,
      perc_fi_women_0_17: 18.4,
      women_18_64: 2608137,
      fi_women_18_64: 341899,
      perc_fi_women_18_64: 13.1,
      women_65_plus: 447489,
      fi_women_65_plus: 38883,
      perc_fi_women_65_plus: 8.7,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-102.744632899185, 21.720510020500065],
          [-102.63389677574399, 21.767435315380062],
          [-102.32508336107753, 21.622260184345066],
          [-102.116088423879, 21.736640590615064],
          [-102.03810523835718, 21.852487412350065],
          [-101.88057920360306, 21.909677615485066],
          [-101.86186323907782, 22.028457268150063],
          [-101.57020612522615, 21.917009692810066],
          [-101.51093890422956, 21.799696455610064],
          [-101.64195065590624, 21.526943179120064],
          [-101.58892208975139, 21.465353729590063],
          [-101.64351031961668, 21.299648782045065],
          [-102.09581279564333, 20.783470538365066],
          [-101.96012205283533, 20.585504450590065],
          [-102.09581279564333, 20.384605531885065],
          [-102.43270015709766, 20.340613067935067],
          [-102.67912702334667, 20.171975289460065],
          [-103.03161102190536, 20.136781318300066],
          [-102.95830682751483, 19.996005433660066],
          [-102.73059592579108, 19.899222012970064],
          [-102.81793709357552, 19.780442360305067],
          [-102.77738583710418, 19.629401567410063],
          [-102.56527157248478, 19.462230204400065],
          [-102.6385757668753, 19.262797701160064],
          [-102.75399088144762, 19.255465623835065],
          [-103.01913371222186, 19.132286724775064],
          [-103.15170512760898, 18.925522144210063],
          [-103.22500932199951, 19.019372733970066],
          [-103.48859248906331, 18.965115361765065],
          [-103.52602441811379, 19.078029352570063],
          [-103.48703282535288, 19.337584889875064],
          [-103.61492524960869, 19.475427943585064],
          [-103.82080085938634, 19.410905663125064],
          [-104.06098907079358, 19.497424175560063],
          [-104.1888814950494, 19.302390918715066],
          [-104.52264952908286, 19.259864870230064],
          [-104.66301926302216, 19.183611266050065],
          [-104.80962765180321, 19.234935807325066],
          [-105.03109989868523, 19.387443015685065],
          [-105.08880745597138, 19.560480040555063],
          [-105.26193012782986, 19.679259693220065],
          [-105.49120069326405, 19.988673356335063],
          [-105.55358724168153, 20.221833415270066],
          [-105.69395697562082, 20.409534594790067],
          [-105.61441412638855, 20.471124044320064],
          [-105.31807802140558, 20.515116508270065],
          [-105.2806460923551, 20.672022963025064],
          [-105.15275366809928, 20.862656973475065],
          [-104.96715368655731, 20.909582268355067],
          [-104.82834361632845, 21.018097012765065],
          [-104.54292515731854, 20.912515099285066],
          [-104.50393356455761, 20.842127156965063],
          [-104.29025963622779, 20.849459234290066],
          [-104.20915712328508, 21.189667622170063],
          [-103.86135211585768, 21.248324240770064],
          [-103.74905632870625, 21.336309168670063],
          [-103.74125801015406, 21.441891082150065],
          [-103.92373866427516, 21.589999044115064],
          [-103.89098572635598, 21.699980203990066],
          [-104.1296142740528, 21.790897962820065],
          [-104.37292181288093, 22.101778041400063],
          [-104.29181929993823, 22.343736593125065],
          [-104.1888814950494, 22.507975125205064],
          [-104.08126469902926, 22.390661888005063],
          [-103.93153698282734, 22.477180400440062],
          [-104.04695209739965, 22.683944981005062],
          [-104.02511680545354, 22.742601599605067],
          [-103.755294983548, 22.719138952165064],
          [-103.85511346101595, 22.575430236595064],
          [-103.6024479399252, 22.556366835550065],
          [-103.5603370197434, 22.422923028235065],
          [-103.63832020526525, 22.295344882780064],
          [-103.67107314318442, 22.109110118725063],
          [-103.43868325032933, 22.117908611515062],
          [-103.40904963983104, 22.214692032205065],
          [-103.2203303308682, 22.411191704515065],
          [-103.0550059775619, 22.241087510575063],
          [-103.1095942074272, 22.016725944430064],
          [-103.27803788815436, 21.990330466060065],
          [-103.42620594064584, 21.890614214440063],
          [-103.65391684236961, 21.436025420290065],
          [-103.59153029395213, 21.128078172640066],
          [-103.43556392290847, 21.141275911825065],
          [-103.35446140996575, 21.045958906600063],
          [-103.126750508242, 21.054757399390063],
          [-103.05656564127234, 21.248324240770064],
          [-102.9099572524913, 21.205798192285066],
          [-102.83665305810077, 21.304048028440064],
          [-102.64325475800662, 21.367103893435065],
          [-102.62453879348138, 21.502014116215065],
          [-102.76334886371023, 21.595864705975064],
          [-102.744632899185, 21.720510020500065],
        ],
      ],
    },
    centerX: 262.2069546551294,
    centerY: 361.0886624324495,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "México",
      ADM1_PCODE: "MX15",
      ADM1_REF: "Mexico",
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 13.430923958,
      Shape_Area: 1.92186081308,
      Region: "Center",
      State: "M√©xico",
      women_0_17: 2430817,
      fi_women_0_17: 662383,
      perc_fi_women_0_17: 27.2,
      women_18_64: 6071041,
      fi_women_18_64: 1211276,
      perc_fi_women_18_64: 20,
      women_65_plus: 782387,
      fi_women_65_plus: 130981,
      perc_fi_women_65_plus: 16.7,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-99.85925503487708, 20.268758710150067],
          [-99.72980294691084, 20.171975289460065],
          [-99.5286063282645, 20.163176796670065],
          [-99.43658616934873, 20.000404680055063],
          [-99.48337608066183, 19.905087674830064],
          [-99.34300634672253, 19.780442360305067],
          [-99.158966028891, 20.026800158425065],
          [-98.9468517642716, 20.026800158425065],
          [-98.96556772879684, 19.891889935645064],
          [-98.77996774725487, 19.883091442855065],
          [-98.59748709313378, 19.765778205655064],
          [-98.666112296393, 19.605938919970065],
          [-98.6536349867095, 19.466629450795065],
          [-98.63335935847383, 19.014973487575066],
          [-98.71446187141653, 18.943119129790066],
          [-98.85951059648715, 18.966581777230065],
          [-98.97960470219078, 19.075096521640063],
          [-98.95776941024467, 19.322920735225065],
          [-99.06850553368567, 19.488625682770063],
          [-99.2229122410189, 19.429969064170066],
          [-99.36484163866865, 19.277461855810067],
          [-99.27906013459463, 19.132286724775064],
          [-99.31337273622424, 18.947518376185066],
          [-99.43034751450699, 18.877130433865066],
          [-99.4942937266349, 18.720223979110067],
          [-99.69705000899167, 18.786212675035067],
          [-99.79374915903874, 18.629306220280064],
          [-99.99026678655378, 18.569183186215064],
          [-100.25696928103844, 18.428407301575064],
          [-100.48779951018308, 18.652768867720063],
          [-100.43165161660735, 18.780347013175067],
          [-100.61257260701802, 18.887395342120065],
          [-100.41449531579255, 19.064831613385067],
          [-100.23669365280277, 19.403573585800064],
          [-100.24293230764451, 19.707121587055063],
          [-100.14779282130787, 19.727651403565066],
          [-100.13843483904526, 20.044397144005064],
          [-99.9278802381363, 20.066393375980063],
          [-99.95127519379285, 20.215967753410066],
          [-99.85925503487708, 20.268758710150067],
        ],
      ],
    },
    centerX: 331.67354633054146,
    centerY: 383.98828527892425,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Michoacán de Ocampo",
      ADM1_PCODE: "MX16",
      ADM1_REF: "Michoacan de Ocampo",
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 20.0978056419,
      Shape_Area: 5.03313817831,
      Region: "Center",
      State: "Michoac√°n",
      women_0_17: 746021,
      fi_women_0_17: 188977,
      perc_fi_women_0_17: 25.3,
      women_18_64: 1468633,
      fi_women_18_64: 291932,
      perc_fi_women_18_64: 19.9,
      women_65_plus: 231407,
      fi_women_65_plus: 35767,
      perc_fi_women_65_plus: 15.5,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-100.35054910366465, 20.287822111195066],
          [-100.19614239633141, 20.221833415270066],
          [-100.13843483904526, 20.044397144005064],
          [-100.14779282130787, 19.727651403565066],
          [-100.24293230764451, 19.707121587055063],
          [-100.23669365280277, 19.403573585800064],
          [-100.41449531579255, 19.064831613385067],
          [-100.61257260701802, 18.887395342120065],
          [-100.79193393371824, 18.834604385380064],
          [-100.7435843586947, 18.517858644940066],
          [-100.92294568539492, 18.444537871690066],
          [-101.28166833879536, 18.544254123310065],
          [-101.50781957680869, 18.487063920175064],
          [-101.63883132848537, 18.602910741910065],
          [-101.87901953989262, 18.539854876915065],
          [-101.8649825664987, 18.286165001470064],
          [-101.97883801736057, 18.206978566360064],
          [-102.14104304324599, 18.187915165315065],
          [-102.17847497229647, 18.039807203350065],
          [-102.30948672397315, 17.969419261030065],
          [-102.78050516452504, 18.082333251835067],
          [-103.50574878987811, 18.340422373675064],
          [-103.72722103676013, 18.702626993530064],
          [-103.48859248906331, 18.965115361765065],
          [-103.22500932199951, 19.019372733970066],
          [-103.15170512760898, 18.925522144210063],
          [-103.01913371222186, 19.132286724775064],
          [-102.75399088144762, 19.255465623835065],
          [-102.6385757668753, 19.262797701160064],
          [-102.56527157248478, 19.462230204400065],
          [-102.77738583710418, 19.629401567410063],
          [-102.81793709357552, 19.780442360305067],
          [-102.73059592579108, 19.899222012970064],
          [-102.95830682751483, 19.996005433660066],
          [-103.03161102190536, 20.136781318300066],
          [-102.67912702334667, 20.171975289460065],
          [-102.43270015709766, 20.340613067935067],
          [-102.09581279564333, 20.384605531885065],
          [-101.9460850794414, 20.343545898865067],
          [-101.93984642459966, 20.227699077130065],
          [-101.69341955835066, 20.192505105970064],
          [-101.62011536396014, 20.315684005030064],
          [-101.45479101065384, 20.318616835960064],
          [-101.39396412594681, 20.053195636795063],
          [-101.20212548956309, 20.025333742960065],
          [-101.01652550802112, 20.085456777025065],
          [-100.9650566055767, 19.928550322270063],
          [-100.77945662403474, 19.915352583085067],
          [-100.66092218204156, 19.965210708895064],
          [-100.36302641334814, 19.990139771800067],
          [-100.35054910366465, 20.287822111195066],
        ],
      ],
    },
    centerX: 292.41868126126786,
    centerY: 386.8679797356144,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Morelos",
      ADM1_PCODE: "MX17",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 4.23207046123,
      Shape_Area: 0.417983771034,
      Region: "Center",
      State: "Morelos",
      women_0_17: 279626,
      fi_women_0_17: 74193,
      perc_fi_women_0_17: 26.5,
      women_18_64: 657027,
      fi_women_18_64: 147808,
      perc_fi_women_18_64: 22.5,
      women_65_plus: 128368,
      fi_women_65_plus: 20225,
      perc_fi_women_65_plus: 15.8,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-98.97960470219078, 19.075096521640063],
          [-98.85951059648715, 18.966581777230065],
          [-98.71446187141653, 18.943119129790066],
          [-98.63335935847383, 19.014973487575066],
          [-98.75345346417745, 18.743686626550065],
          [-98.69886523431217, 18.494395997500064],
          [-98.81584001259492, 18.517858644940066],
          [-99.06538620626479, 18.333090296350065],
          [-99.16052569260144, 18.465067688200065],
          [-99.35548365640602, 18.476799011920065],
          [-99.4942937266349, 18.720223979110067],
          [-99.43034751450699, 18.877130433865066],
          [-99.31337273622424, 18.947518376185066],
          [-99.27906013459463, 19.132286724775064],
          [-99.05914755142305, 19.048701043270064],
          [-98.97960470219078, 19.075096521640063],
        ],
      ],
    },
    centerX: 341.3126260220599,
    centerY: 395.53750354394725,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Nayarit",
      ADM1_PCODE: "MX18",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 18.8369773636,
      Shape_Area: 2.43776179167,
      Region: "North",
      State: "Nayarit",
      women_0_17: 198878,
      fi_women_0_17: 46168,
      perc_fi_women_0_17: 23.2,
      women_18_64: 423169,
      fi_women_18_64: 78862,
      perc_fi_women_18_64: 18.6,
      women_65_plus: 71226,
      fi_women_65_plus: 7113,
      perc_fi_women_65_plus: 10,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-104.34952685722438, 22.409725289050066],
          [-104.29181929993823, 22.343736593125065],
          [-104.37292181288093, 22.101778041400063],
          [-104.1296142740528, 21.790897962820065],
          [-103.89098572635598, 21.699980203990066],
          [-103.92373866427516, 21.589999044115064],
          [-103.74125801015406, 21.441891082150065],
          [-103.74905632870625, 21.336309168670063],
          [-103.86135211585768, 21.248324240770064],
          [-104.20915712328508, 21.189667622170063],
          [-104.29025963622779, 20.849459234290066],
          [-104.50393356455761, 20.842127156965063],
          [-104.54292515731854, 20.912515099285066],
          [-104.82834361632845, 21.018097012765065],
          [-104.96715368655731, 20.909582268355067],
          [-105.15275366809928, 20.862656973475065],
          [-105.2806460923551, 20.672022963025064],
          [-105.48184271100143, 20.755608644530064],
          [-105.45220910050314, 20.874388297195065],
          [-105.24165449959418, 21.051824568460063],
          [-105.24321416330461, 21.343641245995066],
          [-105.18706626972889, 21.459488067730064],
          [-105.44441078195095, 21.623726599810063],
          [-105.63936874575555, 21.978599142340066],
          [-105.62845109978248, 22.273348650805065],
          [-105.66588302883297, 22.581295898455064],
          [-105.46156708276575, 22.507975125205064],
          [-105.52395363118322, 22.77339632437006],
          [-105.39294187950654, 23.084276402950064],
          [-105.2978023931699, 22.953765426565063],
          [-105.01394359787042, 22.977228074005062],
          [-104.91568478411291, 22.748467261465066],
          [-105.07165115515657, 22.656083087170064],
          [-104.96091503171557, 22.505042294275064],
          [-104.77843437759448, 22.642885347985064],
          [-104.65522094446997, 22.462516245790063],
          [-104.494575582295, 22.375997733355064],
          [-104.34952685722438, 22.409725289050066],
        ],
      ],
    },
    centerX: 240.64255711388748,
    centerY: 338.42394231373595,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Nuevo León",
      ADM1_PCODE: "MX19",
      ADM1_REF: "Nuevo Leon",
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 18.142104657,
      Shape_Area: 5.76466083138,
      Region: "North",
      State: "Nuevo Le√≥n",
      women_0_17: 705200,
      fi_women_0_17: 118764,
      perc_fi_women_0_17: 16.8,
      women_18_64: 1747232,
      fi_women_18_64: 235267,
      perc_fi_women_18_64: 13.5,
      women_65_plus: 320230,
      fi_women_65_plus: 26761,
      perc_fi_women_65_plus: 8.4,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-99.84365839777271, 27.769473813625062],
          [-99.7282432832004, 27.678556054795063],
          [-99.92632057442586, 27.549511493875062],
          [-99.76099622111957, 27.43659750307006],
          [-99.74695924772564, 27.276758217385066],
          [-99.65026009767857, 27.065594390425062],
          [-99.73448193804214, 26.920419259390066],
          [-99.58943321297153, 26.86176264079006],
          [-99.61750715975938, 26.728318833475065],
          [-99.3975945765878, 26.596341441625064],
          [-99.43034751450699, 26.351450058970062],
          [-99.26034417006939, 26.253200222815064],
          [-99.17144333857449, 26.09482735259506],
          [-99.023275286083, 26.10362584538506],
          [-98.9000618529585, 25.968715622605064],
          [-98.82363833114711, 26.047902057715064],
          [-98.58500978345029, 26.018573748415065],
          [-98.58189045602941, 25.489197765550063],
          [-98.42280475756488, 25.44813813253006],
          [-98.93437445458811, 25.072735773490063],
          [-99.07942317965873, 25.078601435350066],
          [-99.158966028891, 24.915829318735064],
          [-99.294656771699, 24.804381743395062],
          [-99.60970884120721, 24.622546225735064],
          [-99.58943321297153, 24.39671824412506],
          [-99.6564987525203, 24.11809930577506],
          [-99.4864954080827, 23.961192851020066],
          [-99.61906682346984, 23.760293932315065],
          [-99.86081469858752, 23.774958086965064],
          [-99.96063317605547, 23.556462182680065],
          [-99.92164158329456, 23.379025911415063],
          [-100.0807272817591, 23.395156481530066],
          [-100.10412223741565, 23.230917949450063],
          [-100.37394405932119, 23.26464550514506],
          [-100.51743312068137, 23.421551959900064],
          [-100.44568859000128, 23.676708250810066],
          [-100.55018605860054, 23.842413198355064],
          [-100.52367177552313, 24.071174010895064],
          [-100.63284823525369, 24.226614050185063],
          [-100.6032146247554, 24.401117490520065],
          [-100.74202469498427, 24.459774109120062],
          [-100.79973225227042, 24.556557529810064],
          [-100.82624653534785, 24.835176468160064],
          [-100.80285157969129, 25.165119947785065],
          [-100.59229697878234, 25.254571291150064],
          [-100.3318331391394, 25.185649764295064],
          [-100.25384995361757, 25.30736224789006],
          [-100.47064320936828, 25.338156972655064],
          [-100.59697596991364, 25.544921553220064],
          [-100.78101628774519, 25.638772142980063],
          [-100.90578938458012, 25.95698429888506],
          [-100.91670703055317, 26.075763951550066],
          [-101.04771878222985, 26.16961454131006],
          [-101.2068044806944, 26.369047044550065],
          [-100.75294234095732, 26.73565091080006],
          [-100.69055579253985, 26.613938427205063],
          [-100.54238774004835, 26.806038853120064],
          [-100.5392684126275, 27.047997404845063],
          [-100.83092552647915, 27.07439288321506],
          [-100.83404485390002, 27.194638951345063],
          [-100.67807848285636, 27.335414835985063],
          [-100.41761464321343, 27.394071454585063],
          [-100.35054910366465, 27.684421716655063],
          [-100.18834407777922, 27.79880212292506],
          [-99.99806510510595, 27.615500189800063],
          [-99.84365839777271, 27.769473813625062],
        ],
      ],
    },
    centerX: 325.73165157416116,
    centerY: 266.3562583582218,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Oaxaca",
      ADM1_PCODE: "MX20",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 28.1646736842,
      Shape_Area: 7.86138353933,
      Region: "South",
      State: "Oaxaca",
      women_0_17: 659787,
      fi_women_0_17: 205647,
      perc_fi_women_0_17: 31.2,
      women_18_64: 1294681,
      fi_women_18_64: 349350,
      perc_fi_women_18_64: 27,
      women_65_plus: 228997,
      fi_women_65_plus: 55594,
      perc_fi_women_65_plus: 24.3,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-96.28762513797702, 18.258303107635065],
          [-96.21744027100736, 18.160053271480066],
          [-96.0833091919098, 18.161519686945066],
          [-95.83376299823993, 18.129258546715064],
          [-95.79945039661033, 17.950355859985066],
          [-95.89926887407827, 17.753856187675066],
          [-95.70898990140499, 17.532427452460066],
          [-95.27072439877227, 17.624811626755065],
          [-95.07732609867813, 17.372588166775067],
          [-94.92915804618663, 17.324196456430066],
          [-94.92291939134489, 17.193685480045065],
          [-93.86702705937924, 17.136495276910065],
          [-94.13840854499522, 16.461944163010067],
          [-94.05106737721076, 16.329966771160066],
          [-94.10253627965517, 16.143732007105065],
          [-94.37391776527116, 16.296239215465064],
          [-94.4394236411095, 16.214119949425065],
          [-94.69988748075244, 16.209720703030065],
          [-94.84493620582305, 16.431149438245065],
          [-94.92447905505531, 16.419418114525065],
          [-95.08356475351987, 16.225851273145064],
          [-95.34246892945237, 16.086541803970064],
          [-95.43604875207856, 15.976560644095066],
          [-95.573299158597, 15.956030827585066],
          [-95.95853609507486, 15.828452682130067],
          [-96.2408352266639, 15.684743966560067],
          [-96.5761629244078, 15.668613396445066],
          [-96.86002171970728, 15.740467754230066],
          [-97.21094605455554, 15.923769687355065],
          [-97.58526534506035, 15.992691214210065],
          [-97.76774599918144, 15.976560644095066],
          [-98.17949721873674, 16.231716935005064],
          [-98.55225684553112, 16.315302616510067],
          [-98.34950056317435, 16.444347177430064],
          [-98.394730810777, 16.546996259980066],
          [-98.20445183810372, 16.590988723930067],
          [-98.07188042271662, 16.875473324140067],
          [-98.05784344932268, 17.023581286105067],
          [-98.2200484752081, 17.126230368655065],
          [-98.32298628009693, 17.275804746085065],
          [-98.31206863412386, 17.478170080255065],
          [-98.46179635032578, 17.630677288615065],
          [-98.49454928824495, 17.772919588720065],
          [-98.4243644212753, 17.865303763015064],
          [-98.17637789131587, 17.897564903245065],
          [-98.09527537837316, 17.985549831145065],
          [-97.81921490162586, 17.915161888825065],
          [-97.86756447664939, 18.114594392065065],
          [-97.79737960967975, 18.283232170540064],
          [-97.65545021203, 18.293497078795063],
          [-97.54315442487857, 18.033941541490066],
          [-97.3325998239696, 18.145389116830067],
          [-96.84598474631335, 18.246571783915066],
          [-96.7290099680306, 18.396146161345065],
          [-96.66818308332357, 18.655701698650066],
          [-96.4576284824146, 18.570649601680067],
          [-96.28762513797702, 18.258303107635065],
        ],
      ],
    },
    centerX: 387.03514942747876,
    centerY: 427.89885432006196,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Puebla",
      ADM1_PCODE: "MX21",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 20.8049441813,
      Shape_Area: 2.9440545921,
      Region: "Center",
      State: "Puebla",
      women_0_17: 1047019,
      fi_women_0_17: 325017,
      perc_fi_women_0_17: 31,
      women_18_64: 2065136,
      fi_women_18_64: 537193,
      perc_fi_women_18_64: 26,
      women_65_plus: 315814,
      fi_women_65_plus: 67733,
      perc_fi_women_65_plus: 21.4,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-96.7290099680306, 18.396146161345065],
          [-96.84598474631335, 18.246571783915066],
          [-97.3325998239696, 18.145389116830067],
          [-97.54315442487857, 18.033941541490066],
          [-97.65545021203, 18.293497078795063],
          [-97.79737960967975, 18.283232170540064],
          [-97.86756447664939, 18.114594392065065],
          [-97.81921490162586, 17.915161888825065],
          [-98.09527537837316, 17.985549831145065],
          [-98.17637789131587, 17.897564903245065],
          [-98.4243644212753, 17.865303763015064],
          [-98.43372240353793, 17.984083415680065],
          [-98.65207532299907, 17.934225289870064],
          [-98.76749043757138, 17.989949077540064],
          [-98.88134588843326, 18.165918933340066],
          [-98.99988033042645, 18.190847996245065],
          [-99.06538620626479, 18.333090296350065],
          [-98.81584001259492, 18.517858644940066],
          [-98.69886523431217, 18.494395997500064],
          [-98.75345346417745, 18.743686626550065],
          [-98.63335935847383, 19.014973487575066],
          [-98.6536349867095, 19.466629450795065],
          [-98.52262323503282, 19.460763788935065],
          [-98.36821652769959, 19.226137314535066],
          [-98.16546024534281, 19.104424830940065],
          [-98.04224681221831, 19.210006744420063],
          [-97.92839136135643, 19.164547865005066],
          [-97.77086532660232, 19.283327517670067],
          [-97.64609222976739, 19.306790165110066],
          [-97.87848212262246, 19.569278533345063],
          [-98.25592074054813, 19.718852910775066],
          [-98.26995771394208, 19.896289182040064],
          [-98.10775268805665, 20.120650748185064],
          [-98.1779375550263, 20.295154188520065],
          [-97.98453925493214, 20.516582923735065],
          [-97.89563842343726, 20.622164837215067],
          [-97.87848212262246, 20.834795079640067],
          [-97.77086532660232, 20.825996586850064],
          [-97.67572584026568, 20.554709725825063],
          [-97.76306700805014, 20.397803271070064],
          [-97.68976281365961, 20.166109627600065],
          [-97.49480484985503, 20.138247733765063],
          [-97.44489561112105, 20.246762478175064],
          [-97.16415614324245, 20.151445472950066],
          [-97.31544352315481, 19.824434824255064],
          [-97.30764520460262, 19.685125355080064],
          [-97.43397796514799, 19.588341934390066],
          [-97.33727881510092, 19.391842262080065],
          [-97.00195111735702, 19.236402222790066],
          [-97.26085529328952, 19.098559169080065],
          [-97.24525865618514, 18.893261003980065],
          [-97.35131578849484, 18.793544752360063],
          [-97.34351746994267, 18.685030007950065],
          [-97.14855950613807, 18.597045080050066],
          [-97.07681497545798, 18.466534103665065],
          [-96.95360154233349, 18.554519031565064],
          [-96.7290099680306, 18.396146161345065],
        ],
      ],
    },
    centerX: 361.7595991205311,
    centerY: 390.72592805965536,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Querétaro de Arteaga",
      ADM1_PCODE: "MX22",
      ADM1_REF: "Queretaro de Arteaga",
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 8.18222760708,
      Shape_Area: 1.01463783893,
      Region: "Center",
      State: "Quer√©taro",
      women_0_17: 309934,
      fi_women_0_17: 64693,
      perc_fi_women_0_17: 20.9,
      women_18_64: 716806,
      fi_women_18_64: 116208,
      perc_fi_women_18_64: 16.2,
      women_65_plus: 97686,
      fi_women_65_plus: 9620,
      perc_fi_women_65_plus: 9.8,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-99.05446856029174, 21.282051796465066],
          [-99.04355091431869, 21.179402713915064],
          [-99.21511392246671, 21.106081940665064],
          [-99.30869374509292, 21.147141573685065],
          [-99.38979625803563, 20.953574732305064],
          [-99.54732229278974, 20.727746750695065],
          [-99.50209204518708, 20.658825223840065],
          [-99.82806176066835, 20.542978402105064],
          [-99.85925503487708, 20.268758710150067],
          [-99.95127519379285, 20.215967753410066],
          [-99.9278802381363, 20.066393375980063],
          [-100.13843483904526, 20.044397144005064],
          [-100.19614239633141, 20.221833415270066],
          [-100.35054910366465, 20.287822111195066],
          [-100.47688186421001, 20.389004778280064],
          [-100.49559782873526, 20.616299175355064],
          [-100.55954404086316, 20.830395833245063],
          [-100.47064320936828, 20.906649437425067],
          [-100.06357098094429, 20.935977746725065],
          [-99.9871474591329, 21.218995931470065],
          [-99.82962142437879, 21.157406481940065],
          [-99.73916092917345, 21.235126501585064],
          [-99.77659285822394, 21.494682038890065],
          [-99.65961807994118, 21.566536396675065],
          [-99.53796431052712, 21.431626173895065],
          [-99.40539289514, 21.428693342965065],
          [-99.27906013459463, 21.607596029695063],
          [-99.18236098454756, 21.661853401900064],
          [-99.05446856029174, 21.282051796465066],
        ],
      ],
    },
    centerX: 328.11522107724215,
    centerY: 356.2439298977329,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Quintana Roo",
      ADM1_PCODE: "MX23",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 27.4287078934,
      Shape_Area: 3.73227986885,
      Region: "South",
      State: "Quintana Roo",
      women_0_17: 261237,
      fi_women_0_17: 83072,
      perc_fi_women_0_17: 31.8,
      women_18_64: 583965,
      fi_women_18_64: 150139,
      perc_fi_women_18_64: 25.7,
      women_65_plus: 54360,
      fi_women_65_plus: 14021,
      perc_fi_women_65_plus: 25.8,
    },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-86.72688659299996, 20.578172373265065],
            [-86.93276220277761, 20.312751174100065],
            [-87.01542437943075, 20.415400256650067],
            [-86.92652354793586, 20.550310479430063],
            [-86.72688659299996, 20.578172373265065],
          ],
        ],
        [
          [
            [-89.15216366272905, 17.940090951730063],
            [-89.14280568046642, 18.145389116830067],
            [-89.1459250078873, 19.424103402310067],
            [-89.29721238779966, 19.551681547765064],
            [-89.14436534417686, 19.636733644735067],
            [-88.97436199973926, 19.818569162395065],
            [-88.83243260208953, 19.878692196460065],
            [-88.78408302706598, 20.007736757380066],
            [-88.44407633819078, 20.237963985385065],
            [-88.13682258723475, 20.281956449335063],
            [-87.74378733220469, 20.654425977445065],
            [-87.53323273129574, 20.999033611720066],
            [-87.53479239500618, 21.488816377030062],
            [-87.34139409491202, 21.431626173895065],
            [-87.11056386576739, 21.471219391450063],
            [-87.009185724589, 21.550405826560066],
            [-86.88753195517494, 21.405230695525063],
            [-86.85010002612447, 21.251257071700064],
            [-86.7456025575252, 21.132477419035062],
            [-86.87505464549145, 20.847992818825062],
            [-87.10120588350478, 20.591370112450065],
            [-87.24625460857538, 20.484321783505067],
            [-87.47396551029914, 20.100120931675065],
            [-87.49268147482438, 19.815636331465065],
            [-87.5784629788984, 19.790707268560066],
            [-87.748466323336, 19.598606842645065],
            [-87.49112181111394, 19.561946456020067],
            [-87.43341425382779, 19.485692851840064],
            [-87.56598566921491, 19.284793933135063],
            [-87.55038903211054, 19.088294260825066],
            [-87.71259405799596, 18.707026239925064],
            [-87.76406296044037, 18.418142393320064],
            [-87.8202108540161, 18.331623880885065],
            [-88.04792175573985, 18.528123553195066],
            [-88.00269150813719, 18.737820964690066],
            [-88.06975704768597, 18.860999863750067],
            [-88.28499063972623, 18.494395997500064],
            [-88.51738053258131, 18.463601272735065],
            [-88.71701748751721, 18.060337019860064],
            [-88.87766284969219, 17.894632072315066],
            [-89.03674854815674, 18.004613232190067],
            [-89.15216366272905, 17.940090951730063],
          ],
        ],
      ],
    },
    centerX: 532.677593494457,
    centerY: 378.9124088703825,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "San Luis Potosí",
      ADM1_PCODE: "MX24",
      ADM1_REF: "San Luis Potosi",
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 20.3047367904,
      Shape_Area: 5.36926454594,
      Region: "Center",
      State: "San Luis Potos√≠",
      women_0_17: 436360,
      fi_women_0_17: 91695,
      perc_fi_women_0_17: 21,
      women_18_64: 906558,
      fi_women_18_64: 144624,
      perc_fi_women_18_64: 16,
      women_65_plus: 150188,
      fi_women_65_plus: 24670,
      perc_fi_women_65_plus: 16.4,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-100.10412223741565, 23.230917949450063],
          [-100.09944324628434, 23.008022798770064],
          [-100.02301972447293, 22.726471029490064],
          [-99.84209873406228, 22.730870275885064],
          [-99.4942937266349, 22.620889116010062],
          [-99.3289693733286, 22.635553270660065],
          [-99.23694921441283, 22.412658119980065],
          [-99.06070721513349, 22.415590950910065],
          [-98.89070387069589, 22.355467916845065],
          [-98.67079128752431, 22.405326042655062],
          [-98.34482157204303, 22.222024109530064],
          [-98.43840139466924, 22.114975780585063],
          [-98.46803500516754, 21.966867818620063],
          [-98.57409213747724, 21.943405171180064],
          [-98.45399803177361, 21.767435315380062],
          [-98.63335935847383, 21.654521324575065],
          [-98.50390727050758, 21.389100125410064],
          [-98.60840473910685, 21.201398945890062],
          [-98.82051900372623, 21.160339312870065],
          [-98.93281479087767, 21.298182366580065],
          [-99.05446856029174, 21.282051796465066],
          [-99.18236098454756, 21.661853401900064],
          [-99.27906013459463, 21.607596029695063],
          [-99.40539289514, 21.428693342965065],
          [-99.53796431052712, 21.431626173895065],
          [-99.65961807994118, 21.566536396675065],
          [-99.77659285822394, 21.494682038890065],
          [-99.94191721153022, 21.462420898660064],
          [-100.27256591814282, 21.686782464805063],
          [-100.45192724484303, 21.666252648295064],
          [-100.46440455452652, 21.543073749235063],
          [-100.61569193443889, 21.506413362610065],
          [-100.85743980955658, 21.619327353415066],
          [-100.98221290639151, 21.754237576195063],
          [-101.16157423309174, 21.757170407125066],
          [-101.26139271055969, 21.823159103050063],
          [-101.49690193083563, 21.809961363865064],
          [-101.36121118802764, 21.994729712455065],
          [-101.32377925897715, 22.210292785810065],
          [-101.43451538241817, 22.340803762195065],
          [-101.28790699363711, 22.562232497410065],
          [-101.41112042676161, 22.747000846000063],
          [-101.56708679780529, 22.656083087170064],
          [-101.65598762930017, 22.513840787065064],
          [-101.78388005355599, 22.465449076720063],
          [-101.93984642459966, 22.645818178915064],
          [-102.1925119456904, 22.864314083200064],
          [-102.251779166687, 23.071078663765064],
          [-102.21434723763652, 23.131201697830065],
          [-102.29544975057922, 23.320369292815066],
          [-101.77452207129336, 23.447947438270063],
          [-101.19276750730047, 23.918666802535064],
          [-101.21616246295702, 24.159158938795066],
          [-100.94478097734104, 24.280871422390064],
          [-100.74202469498427, 24.459774109120062],
          [-100.6032146247554, 24.401117490520065],
          [-100.63284823525369, 24.226614050185063],
          [-100.52367177552313, 24.071174010895064],
          [-100.55018605860054, 23.842413198355064],
          [-100.44568859000128, 23.676708250810066],
          [-100.51743312068137, 23.421551959900064],
          [-100.37394405932119, 23.26464550514506],
          [-100.10412223741565, 23.230917949450063],
        ],
      ],
    },
    centerX: 317.9268119943145,
    centerY: 323.8185372794117,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Sinaloa",
      ADM1_PCODE: "MX25",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 41.2498571071,
      Shape_Area: 4.96545075833,
      Region: "North",
      State: "Sinaloa",
      women_0_17: 439758,
      fi_women_0_17: 112316,
      perc_fi_women_0_17: 25.5,
      women_18_64: 996471,
      fi_women_18_64: 205348,
      perc_fi_women_18_64: 20.6,
      women_65_plus: 159267,
      fi_women_65_plus: 27044,
      perc_fi_women_65_plus: 17,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-106.99471651012504, 25.662234790420065],
          [-107.20995010216531, 25.417343407765063],
          [-107.12416859809129, 25.187116179760064],
          [-107.13040725293304, 25.053672372445064],
          [-106.70617872369425, 24.665072274220066],
          [-106.52837706070447, 24.295535577040063],
          [-106.36305270739818, 24.304334069830063],
          [-106.26947288477197, 24.374722012150066],
          [-106.07451492096739, 24.269140098670064],
          [-105.90919056766108, 23.983189082995064],
          [-105.9606594701055, 23.840946782890065],
          [-105.90919056766108, 23.588723322910063],
          [-105.7734998248531, 23.560861429075064],
          [-105.64560740059729, 23.144399437015064],
          [-105.39294187950654, 23.084276402950064],
          [-105.52395363118322, 22.77339632437006],
          [-105.46156708276575, 22.507975125205064],
          [-105.66588302883297, 22.581295898455064],
          [-106.0495603016004, 22.852582759480065],
          [-106.465990512287, 23.260246258750065],
          [-106.48158714939137, 23.327701370140062],
          [-106.73737199790298, 23.603387477560062],
          [-107.01187281093985, 23.952394358230066],
          [-107.63729795882497, 24.464173355515065],
          [-107.99913993964628, 24.653340950500066],
          [-107.97106599285843, 24.791184004210063],
          [-108.0459298509594, 25.062470865235063],
          [-108.3734592301511, 25.263369783940064],
          [-108.65263903431926, 25.344022634515063],
          [-108.78053145857508, 25.442272470670062],
          [-108.77741213115421, 25.53465664496506],
          [-109.15017175794858, 25.587447601705065],
          [-109.42779189840631, 25.829406153430064],
          [-109.44026920808982, 25.964316376210064],
          [-109.21723729749736, 26.322121749670064],
          [-109.11897848373985, 26.303058348625065],
          [-108.83823901586123, 26.477561788960063],
          [-108.53722391974695, 26.79870677579506],
          [-108.50291131811734, 26.987874370780062],
          [-108.24244747847442, 27.042131742985063],
          [-108.22997016879093, 26.905755104740063],
          [-108.09427942598293, 26.87056113358006],
          [-108.00537859448804, 26.760579973705063],
          [-108.06464581548462, 26.647665982900065],
          [-107.96326767430625, 26.527419914770064],
          [-108.01317691304021, 26.43943498687006],
          [-107.79014500244777, 26.168148125845065],
          [-107.41582571194296, 26.141752647475066],
          [-107.30508958850196, 26.075763951550066],
          [-107.29573160623933, 25.924723158655063],
          [-107.05866272225295, 25.757551795645064],
          [-106.99471651012504, 25.662234790420065],
        ],
      ],
    },
    centerX: 194.54818209021676,
    centerY: 277.41916162455516,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Sonora",
      ADM1_PCODE: "MX26",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 41.0929649988,
      Shape_Area: 16.7970051137,
      Region: "North",
      State: "Sonora",
      women_0_17: 444921,
      fi_women_0_17: 117737,
      perc_fi_women_0_17: 26.5,
      women_18_64: 968841,
      fi_women_18_64: 195931,
      perc_fi_women_18_64: 20.2,
      women_65_plus: 162118,
      fi_women_65_plus: 25117,
      perc_fi_women_65_plus: 15.5,
    },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-112.294453798189, 29.224157954905063],
            [-112.2055529666941, 29.034990359920062],
            [-112.31005043529336, 28.747572928780063],
            [-112.54711931927974, 28.857554088655064],
            [-112.49097142570402, 28.947005432020063],
            [-112.47693445231009, 29.166967751770063],
            [-112.294453798189, 29.224157954905063],
          ],
        ],
        [
          [
            [-108.75557683920809, 31.33286339357506],
            [-108.84603733441342, 31.13489730580006],
            [-108.8023667505212, 30.90467007779506],
            [-108.93961715703962, 30.90760290872506],
            [-108.87411128120128, 30.62311830851506],
            [-108.66199701658189, 30.521935641430062],
            [-108.6198860964001, 30.297574075285063],
            [-108.6074087867166, 29.84738452753006],
            [-108.54190291087826, 29.796059986255063],
            [-108.71814491015762, 29.466116506630062],
            [-108.66979533513407, 29.307743636410063],
            [-108.75401717549767, 29.20069530746506],
            [-108.68695163594887, 28.875151074235063],
            [-108.58401383106005, 28.740240851455063],
            [-108.48887434472341, 28.375103400670064],
            [-108.75089784807679, 28.270987902655065],
            [-109.07530789984762, 28.27245431812006],
            [-109.0316373159554, 28.018764442675064],
            [-108.92713984735613, 27.941044423030064],
            [-108.92090119251438, 27.78413796827506],
            [-108.76649448518116, 27.70641794863006],
            [-108.59181214961224, 27.317817850405063],
            [-108.66511634400277, 27.240097830760064],
            [-108.60272979558529, 27.036266081125063],
            [-108.50291131811734, 26.987874370780062],
            [-108.53722391974695, 26.79870677579506],
            [-108.83823901586123, 26.477561788960063],
            [-109.11897848373985, 26.303058348625065],
            [-109.312376783834, 26.568479547790062],
            [-109.5073347476386, 26.728318833475065],
            [-109.68513641062837, 26.675527876735064],
            [-109.83798345425117, 26.770844881960063],
            [-109.91908596719388, 27.065594390425062],
            [-110.3105615585135, 27.15797856472006],
            [-110.49460187634503, 27.28995595657006],
            [-110.62561362802171, 27.618433020730063],
            [-110.57882371670861, 27.882387804430063],
            [-111.07791610404836, 27.93664517663506],
            [-111.27755305898425, 28.08035389220506],
            [-111.49278665102453, 28.386834724390063],
            [-111.71113957048567, 28.458689082175063],
            [-111.7641681366405, 28.592132889490063],
            [-111.92169417139462, 28.71824461948006],
            [-111.92637316252593, 28.813561624705063],
            [-112.15096473682883, 28.961669586670062],
            [-112.16500171022275, 29.114176795030062],
            [-112.25546220542807, 29.326807037455062],
            [-112.39583193936738, 29.33267269931506],
            [-112.38647395710476, 29.49691123139506],
            [-112.74831593792608, 29.91630605438506],
            [-112.77638988471394, 30.225719717500063],
            [-112.85749239765664, 30.39875674237006],
            [-113.07740498082822, 30.667110772465065],
            [-113.12731421956221, 30.810819488035065],
            [-113.06180834372385, 31.03371463871506],
            [-113.10079993648478, 31.18915467800506],
            [-113.53750577540706, 31.29620300695006],
            [-113.637324252875, 31.478038524610064],
            [-113.85567717233614, 31.595351761810065],
            [-114.02568051677375, 31.492702679260063],
            [-114.27210738302274, 31.544027220535064],
            [-114.5933981073727, 31.756657462960064],
            [-114.6822989388676, 31.76105670935506],
            [-114.96459807045665, 31.917963164110063],
            [-114.97551571642971, 32.17751870141506],
            [-114.93496445995835, 32.48253311813506],
            [-114.81331069054428, 32.49426444185506],
            [-113.21153605992578, 32.00008243015006],
            [-112.42546554986568, 31.75812387842506],
            [-111.0747967766275, 31.33286339357506],
            [-109.57595995089781, 31.334329809040064],
            [-108.75557683920809, 31.33286339357506],
          ],
        ],
      ],
    },
    centerX: 136.3618843609353,
    centerY: 184.96897783210366,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Tabasco",
      ADM1_PCODE: "MX27",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 34.351224884,
      Shape_Area: 2.0657239332,
      Region: "South",
      State: "Tabasco",
      women_0_17: 381487,
      fi_women_0_17: 179339,
      perc_fi_women_0_17: 47,
      women_18_64: 808175,
      fi_women_18_64: 317610,
      perc_fi_women_18_64: 39.3,
      women_65_plus: 102708,
      fi_women_65_plus: 35345,
      perc_fi_women_65_plus: 34.4,
    },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-93.29774980506983, 17.947423029055066],
            [-93.38197164543341, 17.645341443265064],
            [-93.41628424706302, 17.705464477330064],
            [-93.29774980506983, 17.947423029055066],
          ],
        ],
        [
          [
            [-93.48958844145355, 17.538293114320066],
            [-93.60812288344674, 17.315397963640066],
            [-93.69234472381032, 17.390185152355066],
            [-93.62215985684067, 17.544158776180065],
            [-93.76720858191128, 17.687867491750065],
            [-93.93877159005932, 17.752389772210066],
            [-94.09005896997168, 17.967952845565065],
            [-94.12593123531173, 18.215777059150064],
            [-93.86546739566879, 18.243638952985066],
            [-93.57069095439626, 18.341888789140064],
            [-93.40536660108997, 18.432806547970067],
            [-93.15893973484097, 18.441605040760066],
            [-92.99205571782423, 18.406411069600065],
            [-92.8844389218041, 18.316959726235066],
            [-93.04508428397908, 18.019277386840066],
            [-93.26499686715066, 18.022210217770066],
            [-93.34453971638294, 17.954755106380066],
            [-93.48958844145355, 17.538293114320066],
          ],
        ],
        [
          [
            [-92.12176336740055, 18.102863068345066],
            [-92.33075830459907, 18.460668441805065],
            [-92.17635159726584, 18.457735610875066],
            [-92.17947092468671, 18.268568015890065],
            [-92.12176336740055, 18.102863068345066],
          ],
        ],
        [
          [
            [-91.96579699635689, 18.016344555910067],
            [-91.63826761716517, 17.874102255805067],
            [-91.6101936703773, 18.095530991020066],
            [-91.55248611309115, 18.158586856015067],
            [-91.21715841534726, 17.975284922890065],
            [-90.98788784991305, 17.962087183705066],
            [-90.98788784991305, 17.815445637205066],
            [-90.98788784991305, 17.250875683180066],
            [-91.44019032593971, 17.250875683180066],
            [-91.50569620177805, 17.398983645145066],
            [-91.67258021879478, 17.444442524560067],
            [-91.65854324540085, 17.674669752565066],
            [-91.77863735110446, 17.706930892795064],
            [-91.78175667852534, 17.853572439295064],
            [-91.99075161572387, 17.919561135220064],
            [-92.43525577319832, 17.570554254550064],
            [-92.57094651600633, 17.550024438040065],
            [-92.7565464975483, 17.344726272940065],
            [-92.82985069193882, 17.479636495720065],
            [-92.97957840814074, 17.511897635950064],
            [-93.10435150497568, 17.907829811500065],
            [-93.27591451312372, 17.982617000215065],
            [-93.02168932832254, 18.033941541490066],
            [-92.8844389218041, 18.316959726235066],
            [-92.89067757664586, 18.444537871690066],
            [-92.65984734750121, 18.522257891335066],
            [-92.64892970152816, 18.393213330415065],
            [-92.4976423216158, 18.311094064375066],
            [-92.19818688921194, 18.223109136475067],
            [-92.19974655292239, 18.121926469390065],
            [-92.03754152703696, 18.094064575555066],
            [-91.96579699635689, 18.016344555910067],
          ],
        ],
        [
          [
            [-92.4680087111175, 18.648369621325067],
            [-92.41342048125222, 18.484131089245064],
            [-92.28240872957554, 18.397612576810065],
            [-92.22002218115806, 18.248038199380066],
            [-92.64425071039685, 18.397612576810065],
            [-92.68324230315777, 18.607309988305065],
            [-92.4680087111175, 18.648369621325067],
          ],
        ],
      ],
    },
    centerX: 454.95237547205926,
    centerY: 410.5133611827022,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Tamaulipas",
      ADM1_PCODE: "MX28",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 49.3304381771,
      Shape_Area: 6.94503354527,
      Region: "North",
      State: "Tamaulipas",
      women_0_17: 557108,
      fi_women_0_17: 92947,
      perc_fi_women_0_17: 16.7,
      women_18_64: 1167018,
      fi_women_18_64: 152148,
      perc_fi_women_18_64: 13,
      women_65_plus: 183873,
      fi_women_65_plus: 18630,
      perc_fi_women_65_plus: 10.1,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-97.89563842343726, 22.223490524995064],
          [-97.98921824606346, 22.349602254985065],
          [-98.30115098815081, 22.461049830325063],
          [-98.67079128752431, 22.405326042655062],
          [-98.89070387069589, 22.355467916845065],
          [-99.06070721513349, 22.415590950910065],
          [-99.23694921441283, 22.412658119980065],
          [-99.3289693733286, 22.635553270660065],
          [-99.4942937266349, 22.620889116010062],
          [-99.84209873406228, 22.730870275885064],
          [-100.02301972447293, 22.726471029490064],
          [-100.09944324628434, 23.008022798770064],
          [-100.10412223741565, 23.230917949450063],
          [-100.0807272817591, 23.395156481530066],
          [-99.92164158329456, 23.379025911415063],
          [-99.96063317605547, 23.556462182680065],
          [-99.86081469858752, 23.774958086965064],
          [-99.61906682346984, 23.760293932315065],
          [-99.4864954080827, 23.961192851020066],
          [-99.6564987525203, 24.11809930577506],
          [-99.58943321297153, 24.39671824412506],
          [-99.60970884120721, 24.622546225735064],
          [-99.294656771699, 24.804381743395062],
          [-99.158966028891, 24.915829318735064],
          [-99.07942317965873, 25.078601435350066],
          [-98.93437445458811, 25.072735773490063],
          [-98.42280475756488, 25.44813813253006],
          [-98.58189045602941, 25.489197765550063],
          [-98.58500978345029, 26.018573748415065],
          [-98.82363833114711, 26.047902057715064],
          [-98.9000618529585, 25.968715622605064],
          [-99.023275286083, 26.10362584538506],
          [-99.17144333857449, 26.09482735259506],
          [-99.26034417006939, 26.253200222815064],
          [-99.43034751450699, 26.351450058970062],
          [-99.3975945765878, 26.596341441625064],
          [-99.61750715975938, 26.728318833475065],
          [-99.58943321297153, 26.86176264079006],
          [-99.73448193804214, 26.920419259390066],
          [-99.65026009767857, 27.065594390425062],
          [-99.74695924772564, 27.276758217385066],
          [-99.76099622111957, 27.43659750307006],
          [-99.92632057442586, 27.549511493875062],
          [-99.7282432832004, 27.678556054795063],
          [-99.59567186781328, 27.64042925270506],
          [-99.47869708953053, 27.479123551555062],
          [-99.50521137260795, 27.336881251450063],
          [-99.42566852337568, 27.178508381230063],
          [-99.44438448790092, 27.018669095545064],
          [-99.269702152332, 26.845632070675062],
          [-99.11061645386746, 26.426237247685066],
          [-98.75033413675658, 26.330920242460063],
          [-98.71914086254785, 26.272263623860063],
          [-98.46179635032578, 26.225338328980065],
          [-98.24812242199596, 26.071364705155062],
          [-97.66636785800307, 26.02590582574006],
          [-97.44333594741062, 25.848469554475063],
          [-97.27645193039389, 25.952585052490065],
          [-97.20314773600336, 25.629973650190063],
          [-97.4043443546497, 25.417343407765063],
          [-97.58682500877079, 25.31909357161006],
          [-97.72563507899966, 25.019944816750062],
          [-97.61957794668996, 24.929027057920063],
          [-97.70847877818485, 24.808780989790066],
          [-97.7693056628919, 24.559490360740064],
          [-97.85664683067634, 24.46563977098006],
          [-97.74747037094578, 24.308733316225066],
          [-97.7693056628919, 23.912801140675064],
          [-97.73343339755183, 23.773491671500064],
          [-97.77086532660232, 23.327701370140062],
          [-97.7615073443397, 23.000690721445064],
          [-97.78802162741712, 22.792459725415064],
          [-97.88784010488507, 22.653150256240064],
          [-97.79737960967975, 22.261617327085062],
          [-97.89563842343726, 22.223490524995064],
        ],
      ],
    },
    centerX: 348.45413081641505,
    centerY: 291.45061503432987,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Tlaxcala",
      ADM1_PCODE: "MX29",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 3.89158370768,
      Shape_Area: 0.343837166361,
      Region: "Center",
      State: "Tlaxcala",
      women_0_17: 206801,
      fi_women_0_17: 67484,
      perc_fi_women_0_17: 32.6,
      women_18_64: 441772,
      fi_women_18_64: 116773,
      perc_fi_women_18_64: 26.4,
      women_65_plus: 62177,
      fi_women_65_plus: 12543,
      perc_fi_women_65_plus: 20.2,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-98.6536349867095, 19.466629450795065],
          [-98.666112296393, 19.605938919970065],
          [-98.38225350109352, 19.608871750900065],
          [-98.25592074054813, 19.718852910775066],
          [-97.87848212262246, 19.569278533345063],
          [-97.64609222976739, 19.306790165110066],
          [-97.77086532660232, 19.283327517670067],
          [-97.92839136135643, 19.164547865005066],
          [-98.04224681221831, 19.210006744420063],
          [-98.16546024534281, 19.104424830940065],
          [-98.36821652769959, 19.226137314535066],
          [-98.52262323503282, 19.460763788935065],
          [-98.6536349867095, 19.466629450795065],
        ],
      ],
    },
    centerX: 357.37634398161197,
    centerY: 382.99436300521086,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Veracruz de Ignacio de la Llave",
      ADM1_PCODE: "MX30",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 56.4113799694,
      Shape_Area: 6.07171427499,
      Region: "South",
      State: "Veracruz",
      women_0_17: 1169734,
      fi_women_0_17: 310529,
      perc_fi_women_0_17: 26.5,
      women_18_64: 2663943,
      fi_women_18_64: 596774,
      perc_fi_women_18_64: 22.4,
      women_65_plus: 483074,
      fi_women_65_plus: 88890,
      perc_fi_women_65_plus: 18.4,
    },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-94.09005896997168, 17.967952845565065],
            [-93.93877159005932, 17.752389772210066],
            [-93.76720858191128, 17.687867491750065],
            [-93.62215985684067, 17.544158776180065],
            [-93.69234472381032, 17.390185152355066],
            [-93.60812288344674, 17.315397963640066],
            [-93.77188757304259, 17.246476436785066],
            [-93.86702705937924, 17.136495276910065],
            [-94.92291939134489, 17.193685480045065],
            [-94.92915804618663, 17.324196456430066],
            [-95.07732609867813, 17.372588166775067],
            [-95.27072439877227, 17.624811626755065],
            [-95.70898990140499, 17.532427452460066],
            [-95.89926887407827, 17.753856187675066],
            [-95.79945039661033, 17.950355859985066],
            [-95.83376299823993, 18.129258546715064],
            [-96.0833091919098, 18.161519686945066],
            [-95.85871761760691, 18.278832924145064],
            [-95.69807225543192, 18.504660905755067],
            [-95.64660335298751, 18.736354549225066],
            [-95.18494289469825, 18.708492655390067],
            [-94.94319501958057, 18.548653369705065],
            [-94.81530259532475, 18.538388461450065],
            [-94.6265832863619, 18.289097832400067],
            [-94.60162866699493, 18.190847996245065],
            [-94.40511103947989, 18.155654025085067],
            [-94.13216989015348, 18.212844228220064],
            [-94.09005896997168, 17.967952845565065],
          ],
        ],
        [
          [
            [-97.67572584026568, 21.924341770135065],
            [-97.56187038940381, 21.767435315380062],
            [-97.34351746994267, 21.587066213185064],
            [-97.38562839012445, 21.529876010050064],
            [-97.61957794668996, 21.785032300960065],
            [-97.67572584026568, 21.924341770135065],
          ],
        ],
        [
          [
            [-97.89563842343726, 22.223490524995064],
            [-97.78958129112756, 22.182430891975066],
            [-97.64297290234651, 21.585599797720064],
            [-97.48388720388198, 21.334842753205066],
            [-97.14388051500677, 20.614832759890064],
            [-96.71497299463667, 20.170508873995065],
            [-96.45138982757287, 19.849363887160067],
            [-96.30322177508137, 19.333185643480064],
            [-96.18000834195688, 19.248133546510065],
            [-95.94137979426006, 18.904992327700064],
            [-95.77605544095377, 18.755417950270065],
            [-95.70431091027368, 18.605843572840065],
            [-95.70743023769455, 18.415209562390064],
            [-95.97413273217923, 18.214310643685064],
            [-96.2189999347178, 18.168851764270066],
            [-96.28762513797702, 18.258303107635065],
            [-96.4576284824146, 18.570649601680067],
            [-96.66818308332357, 18.655701698650066],
            [-96.7290099680306, 18.396146161345065],
            [-96.95360154233349, 18.554519031565064],
            [-97.07681497545798, 18.466534103665065],
            [-97.14855950613807, 18.597045080050066],
            [-97.34351746994267, 18.685030007950065],
            [-97.35131578849484, 18.793544752360063],
            [-97.24525865618514, 18.893261003980065],
            [-97.26085529328952, 19.098559169080065],
            [-97.00195111735702, 19.236402222790066],
            [-97.33727881510092, 19.391842262080065],
            [-97.43397796514799, 19.588341934390066],
            [-97.30764520460262, 19.685125355080064],
            [-97.31544352315481, 19.824434824255064],
            [-97.16415614324245, 20.151445472950066],
            [-97.44489561112105, 20.246762478175064],
            [-97.49480484985503, 20.138247733765063],
            [-97.68976281365961, 20.166109627600065],
            [-97.76306700805014, 20.397803271070064],
            [-97.67572584026568, 20.554709725825063],
            [-97.77086532660232, 20.825996586850064],
            [-97.87848212262246, 20.834795079640067],
            [-97.89563842343726, 20.622164837215067],
            [-97.98453925493214, 20.516582923735065],
            [-98.10619302434623, 20.616299175355064],
            [-98.46023668661535, 20.331814575145064],
            [-98.57877112860854, 20.478456121645063],
            [-98.47895265114059, 20.594302943380065],
            [-98.53198121729544, 20.716015426975066],
            [-98.44308038580054, 20.834795079640067],
            [-98.25124174941683, 20.897850944635064],
            [-98.14986360823845, 21.082619293225065],
            [-98.22784679376028, 21.150074404615065],
            [-98.41656610272312, 21.154473651010065],
            [-98.54133919955805, 21.268854057280066],
            [-98.50390727050758, 21.389100125410064],
            [-98.63335935847383, 21.654521324575065],
            [-98.45399803177361, 21.767435315380062],
            [-98.57409213747724, 21.943405171180064],
            [-98.46803500516754, 21.966867818620063],
            [-98.43840139466924, 22.114975780585063],
            [-98.34482157204303, 22.222024109530064],
            [-98.67079128752431, 22.405326042655062],
            [-98.30115098815081, 22.461049830325063],
            [-97.98921824606346, 22.349602254985065],
            [-97.89563842343726, 22.223490524995064],
          ],
        ],
      ],
    },
    centerX: 387.7069310720748,
    centerY: 383.59457741390645,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Yucatán",
      ADM1_PCODE: "MX31",
      ADM1_REF: "Yucatan",
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 11.4656086236,
      Shape_Area: 3.42031500572,
      Region: "South",
      State: "Yucat√°n",
      women_0_17: 333879,
      fi_women_0_17: 94877,
      perc_fi_women_0_17: 28.4,
      women_18_64: 738210,
      fi_women_18_64: 159388,
      perc_fi_women_18_64: 21.6,
      women_65_plus: 118712,
      fi_women_65_plus: 18012,
      perc_fi_women_65_plus: 15.2,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-87.53479239500618, 21.488816377030062],
          [-87.53323273129574, 20.999033611720066],
          [-87.74378733220469, 20.654425977445065],
          [-88.13682258723475, 20.281956449335063],
          [-88.44407633819078, 20.237963985385065],
          [-88.78408302706598, 20.007736757380066],
          [-88.83243260208953, 19.878692196460065],
          [-88.97436199973926, 19.818569162395065],
          [-89.14436534417686, 19.636733644735067],
          [-89.29721238779966, 19.551681547765064],
          [-89.4687753959477, 19.699789509730063],
          [-89.5873098379409, 19.874292950065065],
          [-89.59822748391394, 19.997471849125063],
          [-90.00374004862749, 20.487254614435066],
          [-90.0676862607554, 20.425665164905062],
          [-90.38741732139492, 20.556176141290067],
          [-90.38117866655318, 20.847992818825062],
          [-90.38741732139492, 20.846526403360066],
          [-90.40457362220972, 20.846526403360066],
          [-90.30631480845221, 21.031294751950064],
          [-90.09420054383283, 21.155940066475065],
          [-89.83061737676901, 21.252723487165063],
          [-89.40015019268849, 21.330443506810063],
          [-88.8402309206417, 21.399365033665063],
          [-88.62811665602231, 21.515211855400064],
          [-87.96369991537627, 21.609062445160063],
          [-87.53479239500618, 21.488816377030062],
        ],
      ],
    },
    centerX: 518.647675188767,
    centerY: 358.27879577242885,
  },
  {
    type: "Feature",
    properties: {
      ADM1_ES: "Zacatecas",
      ADM1_PCODE: "MX32",
      ADM1_REF: null,
      ADM1ALT1ES: null,
      ADM1ALT2ES: null,
      ADM0_ES: "México",
      ADM0_PCODE: "MX",
      date: "2020/06/23",
      validOn: "2021/06/18",
      validTo: null,
      Shape_Leng: 29.8686666484,
      Shape_Area: 6.64490699795,
      Region: "North",
      State: "Zacatecas",
      women_0_17: 256572,
      fi_women_0_17: 49006,
      perc_fi_women_0_17: 19.1,
      women_18_64: 503655,
      fi_women_18_64: 78041,
      perc_fi_women_18_64: 15.5,
      women_65_plus: 87949,
      fi_women_65_plus: 11992,
      perc_fi_women_65_plus: 13.6,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-100.79973225227042, 24.556557529810064],
          [-100.74202469498427, 24.459774109120062],
          [-100.94478097734104, 24.280871422390064],
          [-101.21616246295702, 24.159158938795066],
          [-101.19276750730047, 23.918666802535064],
          [-101.77452207129336, 23.447947438270063],
          [-102.29544975057922, 23.320369292815066],
          [-102.21434723763652, 23.131201697830065],
          [-102.251779166687, 23.071078663765064],
          [-102.1925119456904, 22.864314083200064],
          [-101.93984642459966, 22.645818178915064],
          [-101.78388005355599, 22.465449076720063],
          [-101.65598762930017, 22.513840787065064],
          [-101.56708679780529, 22.656083087170064],
          [-101.41112042676161, 22.747000846000063],
          [-101.28790699363711, 22.562232497410065],
          [-101.43451538241817, 22.340803762195065],
          [-101.32377925897715, 22.210292785810065],
          [-101.36121118802764, 21.994729712455065],
          [-101.49690193083563, 21.809961363865064],
          [-101.51093890422956, 21.799696455610064],
          [-101.57020612522615, 21.917009692810066],
          [-101.86186323907782, 22.028457268150063],
          [-101.96792037138752, 22.110576534190066],
          [-102.03654557464674, 22.298277713710064],
          [-102.32196403365666, 22.389195472540063],
          [-102.46545309501683, 22.349602254985065],
          [-102.64169509429618, 22.227889771390064],
          [-102.87096565973037, 21.846621750490065],
          [-102.744632899185, 21.720510020500065],
          [-102.76334886371023, 21.595864705975064],
          [-102.62453879348138, 21.502014116215065],
          [-102.64325475800662, 21.367103893435065],
          [-102.83665305810077, 21.304048028440064],
          [-102.9099572524913, 21.205798192285066],
          [-103.05656564127234, 21.248324240770064],
          [-103.126750508242, 21.054757399390063],
          [-103.35446140996575, 21.045958906600063],
          [-103.43556392290847, 21.141275911825065],
          [-103.59153029395213, 21.128078172640066],
          [-103.65391684236961, 21.436025420290065],
          [-103.42620594064584, 21.890614214440063],
          [-103.27803788815436, 21.990330466060065],
          [-103.1095942074272, 22.016725944430064],
          [-103.0550059775619, 22.241087510575063],
          [-103.2203303308682, 22.411191704515065],
          [-103.40904963983104, 22.214692032205065],
          [-103.43868325032933, 22.117908611515062],
          [-103.67107314318442, 22.109110118725063],
          [-103.63832020526525, 22.295344882780064],
          [-103.5603370197434, 22.422923028235065],
          [-103.6024479399252, 22.556366835550065],
          [-103.85511346101595, 22.575430236595064],
          [-103.755294983548, 22.719138952165064],
          [-104.02511680545354, 22.742601599605067],
          [-104.04695209739965, 22.683944981005062],
          [-103.93153698282734, 22.477180400440062],
          [-104.08126469902926, 22.390661888005063],
          [-104.1888814950494, 22.507975125205064],
          [-104.29181929993823, 22.343736593125065],
          [-104.34952685722438, 22.409725289050066],
          [-104.16548653939284, 22.942034102845064],
          [-104.0656680619249, 23.464078008385066],
          [-103.8831874078038, 23.591656153840063],
          [-103.81612186825502, 23.807219227195063],
          [-103.80988321341327, 24.04037928613006],
          [-103.55877735603296, 24.194352909955064],
          [-103.42932526806672, 24.411382398775064],
          [-103.04252866787841, 24.467106186445065],
          [-102.70408164271365, 24.374722012150066],
          [-102.53407829827604, 24.408449567845064],
          [-102.47949006841075, 24.490568833885064],
          [-102.56683123619521, 24.902631579550064],
          [-102.66976904108404, 25.066870111630063],
          [-102.44673713049158, 24.999415000240063],
          [-102.46389343130639, 25.115261821975064],
          [-102.23774219329307, 25.118194652905064],
          [-101.84938592939433, 25.017011985820062],
          [-101.77296240758292, 24.876236101180062],
          [-101.60139939943488, 24.835176468160064],
          [-101.46102966549559, 24.736926632005066],
          [-101.3424952235024, 24.81318023618506],
          [-101.20212548956309, 24.764788525840064],
          [-101.12726163146213, 24.624012641200064],
          [-100.95881795073497, 24.54335979062506],
          [-100.79973225227042, 24.556557529810064],
        ],
      ],
    },
    centerX: 278.9217772747457,
    centerY: 310.2056271649326,
  },
];
