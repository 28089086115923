import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
    border: 2px dotted;
    border-color: ${props => props.$color};
    color: ${props => props.$color};
    border-radius: 25px;
    padding: 25px 20px;
    font-family: Georgia, serif;
    width: ${props => props.$hug ? 'fit-content' : 'auto'};

    h3 {
        font-size: 1.5em;
        margin: 0px;
        font-weight: 400;
    }
`;

function BannerText({
    text,
    color,
    hug
}) {
    return (
        <Container $color={color} $hug={hug}>
            <h3>{text}</h3>
        </Container>
    );
}

BannerText.propTypes = {
    // By default all props are optional. Please add the isRequired bool if you need them to be mandatory. 
    text: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    hug: PropTypes.bool.isRequired
}

BannerText.defaultProps = {
    text: 'Banner Text that can hug',
    color: '#222',
    hug: false
}

export default BannerText;