import { ViewWrapper, TextContainer, TextCenter, ChapterSection } from "../components/wrappers";

import SplitScreen from "../components/layout/splitscreen";
import ChapterButton from "../components/general/chapterButton";
import ChapterNumber from "../components/view-specific/chapters/chapterNum";
import ChapterTitle from "../components/view-specific/chapters/chapterTitle";
import Navbar from "../components/general/navbar";
import SliderList from "../components/general/slider";
import Organizations from "../components/general/org";
import { ch3Slider } from "../data";
import RenderFooter from "../components/general/renderFooter";

import { useEffect } from 'react';
import ch2_1 from '../resources/chapters/2/img1.webp';

import ReactGA from "react-ga4";



function Chapter3() {
    useEffect(() => {
      window.scrollTo(0,0);
      ReactGA.send({ hitType: "pageview", page: "/ch3", title: "Hunger is a Result of Inequity" });
    }, []);

    return (
        <ViewWrapper>
            <Navbar />
            <ChapterSection>
                <TextContainer>
                    <TextCenter>
                        <ChapterNumber number={3} />
                        <ChapterTitle title="Hunger is a Result of Inequity" />
                        <ChapterTitle sub title='Holistic solutions to systemic inequalities' color={'var(--textColor)'} />
                    </TextCenter>
                </TextContainer>
            </ChapterSection>

            <ChapterSection>
                <SliderList list={ch3Slider}/>
            </ChapterSection>

            <ChapterSection>
                <TextContainer>
                    <p>The prevalence of food insecurity and its relationship to domestic violence against women cannot be ignored. However, they are also related to other challenges women face — education, employment and access to family planning resources among others. Therefore, it is essential to stop treating these social problems as isolated events. Instead, we must weave a network of support and empowerment that addresses these problems in a systemic way.</p>
                </TextContainer>
                <TextContainer>
                    <p>This vision has been adopted by some NGOs. In addition to addressing specific issues such as food insecurity or violence against women, these organizations have developed programs or projects that address a wider range of problems. In collaboration with other stakeholders, they are addressing not only the consequences of these problems, but also their underlying causes.</p>
                </TextContainer>
                <TextContainer>
                    <p>This approach is essential to breaking the cycle of violence and food insecurity. By providing comprehensive support to individuals and families, we can help them to overcome these challenges and build a better future for themselves.</p>
                </TextContainer>
            </ChapterSection>

            <ChapterSection>
                <TextContainer>
                    <p><b>Check out the work of the below NGO´s and get involved into the</b> challenges of hunger and violence for women in the country.</p>
                </TextContainer>
            </ChapterSection>


            <ChapterSection>
                <Organizations />
            </ChapterSection>

            <ChapterSection>
                <TextContainer>
                    <p>As a network nonprofit organization, Cemefi works with more than 2,500 NGOs across Mexico, and regularly maps the more than 48,000 registered NGOs that work collaboratively on a variety of themes to achieve fair social change. If you want to learn about and actively participate in their initiatives, consult Cemefi's <a href="https://directorioif.cemefi.org/" target="_blank" rel="noreferrer" style={{color: 'unset'}}> Directory of Philanthropic Institutions</a>.</p>
                </TextContainer>
            </ChapterSection>


            <ChapterSection>

              <SplitScreen gap="12.5vw" reversedColumn={true}>
                  <ChapterButton
                      title="Previous Chapter"
                      subtitle="Food Insecurity & Gender-based Violence"
                      image={ch2_1}
                      handler={'/ch2'}
                  />
                  <div></div>
              </SplitScreen>

            </ChapterSection>

            <RenderFooter />
        </ViewWrapper>
    );
}

export default Chapter3;
