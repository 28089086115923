import styled from 'styled-components';
import layout from "../../css-modules/layouts.module.css";
import { useRef } from 'react';
import Parallax from '../layout/parralax';

const SliderImage = styled.img.attrs(props => ({
    src: props.$source,
    alt: props.$alt,
}))`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;


const ImageContainer = styled.div.attrs(props => ({
    title: props.$alt
}))`
    position: relative;
    width: 35vw;
    height: 35vw;
    min-width: 300px;
    min-height: 300px;
    overflow: hidden;
    border-radius: 0.95rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: var(--darkgray);
        opacity: 0.2;
    }
`;


const ItemContainer = styled.div`
    margin-right: 50px;
    transform: translateX(calc(-25% + 37.5px));

    &:last-child {
        margin-right: 0px;
    }

    p {
        margin-top: 20px;
        display: none;
    }
`


function SliderItem ({ img, caption, index }) {
    const image = useRef();

    return (
        <ItemContainer title={caption} $index={index} className={layout["flexed-column"]}>
            <ImageContainer>
                <Parallax
                    to={{ scale: 1.25, y: -35 }}
                    from={{ scale: 1.2, y: 0 }}
                    settings={{ start: "top center", end: `+=${600*index}`, scrub: 2.5 }}
                    style={{width: '100%', height: '100%'}}
                >
                    <SliderImage ref={image} $alt={caption} $source={img}></SliderImage>
                </Parallax>
            </ImageContainer>
            <p>{caption}</p>
        </ItemContainer>
    );
}

const ListContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100vw;
    overflow-x: hidden;
`

function SliderList ( { list } ) {
    const container = useRef();


    return (
        <ListContainer ref={container}>
            {list.length > 0 && list.map((item, index) => {
                return (
                    <SliderItem key={index + 'slide'} img={item.img} caption={item.caption} {...{index}}/>
                );
            })}
        </ListContainer>
    );
}

export default SliderList;