import styled from 'styled-components';
import PropTypes from 'prop-types';

function ChapterTitle({
    title,
    color,
    sub
}) {
    return (
        <Container $fill={color} $font={!sub ? "Replica-Bold" : "Georgia"} $fontSize={!sub ? 'clamp(1.1rem, 0.314rem + 2.095vw, 2.2rem)' : 'clamp(0.6rem, 0.171rem + 1.143vw, 1.2rem)'} $weight={!sub ? "600" : "400"}>
            <h1 aria-level={!sub ? "1" : "2"}>{title}</h1>
        </Container>
    );
}

ChapterTitle.propTypes = {
    // By default all props are optional. Please add the isRequired bool if you need them to be mandatory.
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    sub: PropTypes.bool.isRequired
}

ChapterTitle.defaultProps = {
    title: 'This is a Chapter Title - Mexico DC2',
    color: '#000',
    sub: false
}

const Container = styled.div`
    color: ${props => props.$fill};
    margin: 15px 0px;
    font-family: ${props => props.$font}, sans-serif;
    font-size: ${props => props.$fontSize};

    h1 {
        font-weight: ${props => props.$weight};
    }
`

export default ChapterTitle;