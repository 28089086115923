import styled from 'styled-components';
import PropTypes from 'prop-types';

const SVG = styled.svg.attrs({
    viewBox: '0 0 100 100',
})`
    fill: none;

    & circle {
        stroke-width: ${props => props.$strokeWidth};
        stroke: ${props => props.$stroke};
        stroke-linecap: round;
    }
`;

function Circle({
    number,
    gap,
    strokeColor,
    strokeWidth,
    divider
}) {
    return (
        <SVG
            $stroke={strokeColor}
            $strokeWidth={strokeWidth}
        >

            {renderCircles({...{number, gap, strokeWidth, divider}})}
        </SVG>
    );
}

function renderCircles({
    number,
    gap,
    strokeWidth,
    divider
}) {
    let containers = [];
    for (let i = 0; i < number; i++) {
        let radius = 50 - (gap * i) - strokeWidth/2;
        let d = divider - (i * gap);
        containers.push(
            <circle
                key={'circle' + i} 
                cx="50" cy="50" 
                r={radius}
                strokeDasharray={`0, ${(2 * Math.PI * (radius))/d}`}
            />
        );
    }
    return containers;
}

Circle.propTypes = {
    // By default all props are optional. Please add the isRequired bool if you need them to be mandatory. 
    number: PropTypes.number.isRequired,
    gap: PropTypes.number.isRequired,
    strokeColor: PropTypes.string.isRequired,
    strokeWidth: PropTypes.number.isRequired,
    divider: PropTypes.number.isRequired
}

Circle.defaultProps = {
    number: 4,
    gap: 8,
    strokeColor: 'var(--darkblue)',
    strokeWidth: 1.5,
    divider: 60,
}
  
export default Circle;
  