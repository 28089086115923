import styled from 'styled-components';
import PropTypes from 'prop-types';

function ChapterNumber({
    number,
    color
}) {
    return (
        <Container $fill={color}>
            <p>-<Title>Chapter {number}</Title>-</p>
        </Container>
    );
}

ChapterNumber.propTypes = {
    // By default all props are optional. Please add the isRequired bool if you need them to be mandatory.
    number: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired
}

ChapterNumber.defaultProps = {
    number: 1,
    color: 'var(--deepblue)'
}

const Container = styled.div`
    margin: 15px 0px;
    font-size: 1.05em;

    p {
        color: ${props => props.$fill} !important;
        font-family: Georgia-Italic, serif !important;
        font-style: italic;
    }
`

const Title = styled.span`
    margin: 0px 5px;
`

export default ChapterNumber;