import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.footer`
    position: relative;
`

const Colored = styled.div`
    margin-top: 40px;
    background-color: ${props => props.$fill};
    color: ${props => props.$textFill} !important;
    padding: 60px var(--navPadding);
    border-radius: 10px 10px 0px 0px;
`

const CopyRight = styled.div`
    padding: 1em var(--navPadding);
    font-size: 0.8em;
    display: flex;
    justify-content: space-between;

    .georgia {
        font-family: 'Georgia-Italic';
    }

    .googleLogo {
        width: 75px;
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;


function Footer({
    children,
    fill,
    textFill,
    copyright
}) {
    return (
        <Container>
            <Colored $fill={fill} $textFill={textFill}>
                {children}
            </Colored>
            <CopyRight>
                <p>Copyright © {new Date().getFullYear()}, {copyright}. All Rights Reserved.</p>
            </CopyRight>
        </Container>
    );
}

Footer.propTypes = {
    fill: PropTypes.string.isRequired,
    textFill: PropTypes.string.isRequired,
    copyright: PropTypes.string.isRequired,
}

Footer.defaultProps = {
    fill: 'var(--deepblue)',
    textFill: '#fff',
    copyright: 'TechSoup Global'
}

export default Footer;

