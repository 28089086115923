import { ViewWrapper, ContentContainer, TextContainer, TextCenter, ChapterSection, GraySection } from "../components/wrappers";
import layout from "../css-modules/layouts.module.css";
import { applyClasses, device } from "../utilities";
import styled from 'styled-components';

import Statistic from "../components/general/statistic";
import IoRenderer from "../components/general/ioRenderer";

import SplitScreen from "../components/layout/splitscreen";
import BannerText from "../components/general/bannerText";
import ChapterButton from "../components/general/chapterButton";
import ChapterNumber from "../components/view-specific/chapters/chapterNum";
import ChapterTitle from "../components/view-specific/chapters/chapterTitle";
import Navbar from "../components/general/navbar";
import MapFrames from "../components/MapFrames";
import SliderList from "../components/general/slider";
import { ch1Slider } from "../data";
import RenderFooter from "../components/general/renderFooter";
import LineFrames from '../components/LineFrames';

import { ChartSection } from "../components/wrappers";

import { ch1_desc_line, ch1_desc_map } from "../data";
import IO_List from "../components/layout/io_list";
import StickyVis from "../components/layout/stickyVis";

import { useEffect, useState } from 'react';

import ch2_1 from '../resources/chapters/2/img1.webp';

import ReactGA from "react-ga4";

const BackgroundIO = styled.div`
  background-color: unset;

  @media ${device.laptop} {
    background-color: #eee;
  }
`;

function Home() {
    const [lineFrameIndex, setLineFrameIndex] = useState(0);
    const [mapFrameIndex, setMapFrameIndex] = useState(0);
    useEffect(() => {
      window.scrollTo(0,0);
      ReactGA.send({ hitType: "pageview", page: "/ch1", title: "Food Insecurity in Mexican Women" });
    }, []);

    return (
        <ViewWrapper>
            <Navbar/>
            <ChapterSection>
              <TextContainer>
                  <TextCenter>
                    <ChapterNumber/>
                    <ChapterTitle title="Food Insecurity in Mexican Women" />
                    <ChapterTitle sub title='Circumstances that link to inequality' color={'var(--textColor)'}/>
                  </TextCenter>
              </TextContainer>
            </ChapterSection>

            <ChapterSection >
              <SliderList list={ch1Slider}/>
            </ChapterSection>

            <ChapterSection>
              <TextContainer>
                <p>Even though access to quality food is central to human development, a high percentage of Mexico’s population lacks access to sufficient, safe and nutritious food in their daily lives. According to <a href='https://datacommons.org/tools/timeline#place=country%2FMEX&statsVar=sdg%2FAG_PRD_FIESMS' target='_blank' rel='noreferrer'>UN data from Data Commons</a>, 26.1 percent of Mexico’s population faced moderate to severe food insecurity in 2020. That amounts to approximately 26 million people who were unable to fulfill their dietary needs necessary to maintain a healthy lifestyle.</p>
              </TextContainer>
              <ContentContainer>
                <Statistic fill={'var(--darkblue)'}/>
              </ContentContainer>
              <TextContainer>
                <p>Some data sources suggest that the gender disparity among those suffering from food insecurity is significantly high. UN statistics referenced in Data Commons show that among the Mexican population aged 15 or older, the incidence of food insecurity amongst females was 12.3 percentage points higher than the male population in 2020. Moreover, the data also indicates that the gender gap has consistently increased between 2015 and 2020.</p>
              </TextContainer>
            </ChapterSection>

            <ChapterSection>
              <GraySection>
                <ChartSection>
                  <StickyVis
                      source={<a target="_blank" rel='noreferrer' href='https://dev.datacommons.org/tools/visualization#visType%3Dtimeline%26place%3Dcountry%2FMEX%26placeType%3DAdministrativeArea1%26sv%3D%7B%22dcid%22%3A%22sdg%2FAG_PRD_FIESMS.AGE--Y_GE15__SEX--F%22%7D___%7B%22dcid%22%3A%22sdg%2FAG_PRD_FIESMS.AGE--Y_GE15__SEX--M%22%7D___%7B%22dcid%22%3A%22sdg%2FAG_PRD_FIESMS%22%7D'>UN via Data Commons</a>}
                  >
                    <BackgroundIO className={applyClasses(layout, ["flexed-column", "align-center"])} >
                      <IO_List fill='var(--blue)' margin='var(--ioMargin)' data={ch1_desc_line} element={IoRenderer} callback={setLineFrameIndex} source={<a target="_blank" rel='noreferrer' href='https://datacommons.org/tools/timeline#statsVar=sdg%2FAG_PRD_FIESMS&place=country%2FMEX&chart=%7B%22none-none%22%3A%7B%22pc%22%3Afalse%2C%22delta%22%3Afalse%7D%7D'>UN via Data Commons</a>}/>
                    </BackgroundIO>
                    <LineFrames state={lineFrameIndex}/>
                  </StickyVis>
                </ChartSection>
              </GraySection>
            </ChapterSection>

            <ChapterSection>
              <TextContainer>
                <p>To find a state-level breakdown of the data on hunger among women in Mexico, we turned to CONEVAL, a government agency that focuses on poverty indicators in Mexico. However, it is important to note that comparing data across different sources can be challenging, as we found contradictory results. These discrepancies can be attributed to differences in methodologies and time frames in which the surveys were conducted.</p>
              </TextContainer>
              <TextContainer>
                <p>For example, while data from UN stats for the year 2020 showed a 12.3 percent gap between Mexican men and women (aged 15 years or older) in terms of food insecurity, CONEVAL reports this gap to be less than one percent, with men having a higher incidence of food insecurity.</p>
              </TextContainer>
              <TextContainer>
                <p>Resolving this discrepancy is beyond the scope of this report. However, we will use CONEVAL's data to get a clearer picture of hunger among women at a more local level.</p>
              </TextContainer>
            </ChapterSection>

            <ChapterSection>
              <ChartSection>
                <StickyVis
                    source={<a target="_blank" rel='noreferrer' href='https://www.coneval.org.mx/Medicion/Paginas/Pobreza-municipio-2010-2020.aspx'>CONEVAL</a>}
                >
                  <IO_List fill='var(--blue)' margin='800px 0px' data={ch1_desc_map} element={IoRenderer} callback={setMapFrameIndex} source={<a href='https://www.coneval.org.mx/Medicion/Paginas/Pobreza-municipio-2010-2020.aspx'>CONEVAL</a>}/>
                  <MapFrames state={mapFrameIndex}/>
                </StickyVis>
              </ChartSection>
            </ChapterSection>

            <ChapterSection>
              <ContentContainer>
                <BannerText 
                  text="We have seen that food insecurity is a widespread problem for Mexican women. In the next chapter, we will see how it interacts with other aspects of womens’ lives."
                  color='var(--darkblue)'
                />
              </ContentContainer>
            </ChapterSection>



            <ChapterSection>
              <SplitScreen gap="12.5vw" reversedColumn={true}>
                  <div></div>
                  <ChapterButton
                      title="Next Chapter"
                      subtitle="Food Insecurity & Gender-based Violence"
                      direction="backward"
                      image={ch2_1}
                      handler={'/ch2'}
                  />
              </SplitScreen>
            </ChapterSection>
            
            <RenderFooter/>

        </ViewWrapper>
    );
  }

  export default Home;
