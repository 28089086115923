const foodInsRates = [
  {
    year: 2015,
    female_sev: 0.08,
    male_sev: 0.066,
    male_sev_mod: 0.247,
    female_sev_mod: 0.271,
  },
  {
    year: 2016,
    female_sev: 0.084,
    male_sev: 0.064,
    male_sev_mod: 0.24600000000000002,
    female_sev_mod: 0.285,
  },
  {
    year: 2017,
    female_sev: 0.106,
    male_sev: 0.083,
    male_sev_mod: 0.27,
    female_sev_mod: 0.326,
  },
  {
    year: 2018,
    female_sev: 0.131,
    male_sev: 0.107,
    male_sev_mod: 0.31,
    female_sev_mod: 0.392,
  },
  {
    year: 2019,
    female_sev: 0.124,
    male_sev: 0.098,
    male_sev_mod: 0.28600000000000003,
    female_sev_mod: 0.391,
  },
  {
    year: 2020,
    female_sev: 0.113,
    male_sev: 0.083,
    male_sev_mod: 0.258,
    female_sev_mod: 0.381,
  },
];

foodInsRates.forEach((d) => {
  d.year = new Date(d.year, 0);
});

export { foodInsRates };
