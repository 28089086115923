import styled from 'styled-components';

export const ViewWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin: auto;
    padding: 0px 4vw;
`;

export const NarrowContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px var(--navPadding);
`;

export const NavContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px var(--navPadding);
`;

export const ChapterSection = styled.div`
    display: flex;
    flex-direction: column;
    padding: 45px 0px;
    padding-top: clamp(30px, 5%, 50px);
    padding-bottom: clamp(30px, 5%, 50px);
`

export const TextContainer = styled.div`
    padding: 0px calc(var(--textPadding) + var(--paddingSide));
    color: var(--textColor);
    max-width: 800px;
    margin: auto;

    p {
        margin: 0.8em 1.5em;
        line-height: 140%;
    }
`;

export const ChartSection = styled.div`
    padding-left: clamp(1em, 5vw, 8em);
    padding-right: clamp(1em, 5vw, 8em);
`;

export const GraySection = styled.div`
    background-color: var(--grayOpaque);
`

export const TextCenter = styled.div`
    text-align: center;
`
