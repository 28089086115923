import styled from 'styled-components';
import Powered from './poweredBy';

const Container = styled.div`
    width: 90%;
    margin: 0px 5%;
    padding: 20px;
    box-sizing: border-box;
    border: 2px dashed ${props => props.$color};
    border-radius: 15px;

    h3 {
        margin-top: 0px;
        font-family: 'Georgia-Italic';

        @media screen and (min-width: 1600px) {
            font-size: 1.5em;
            line-height: 1.6em;
        }

        @media (max-width: 400px) {
            font-size: 1.1em;
        }
    }

    i {
        font-family: 'Georgia-Italic';
    }

    .second_para {
        margin-top: 10px;
    }

    @media (max-width: 1150px) {
        width: 90%;
        margin-right: 10%;
    }
    @media (max-width: 1023px) {
        width: 100%;
        margin-right: 0%;
        background-color: rgba(255,255,255, 0.8);
        border: 0px;
    }

    .mobile {
        display: none;
    }

    @media (max-width: 1023px) {
        .mobile {
            display: block;
            margin-top: 10px;
            font-size: 0.9em;

            a {
                color: black;
            }

            div {
                justify-content: flex-start !important;
            }
        }
    }
`

export default function IoRenderer ({ data, fill, source }) {
    return (
        <Container $color={fill}>
            {data}
            <div className='mobile'>
                <p className='source'>Source: {source}</p>
                <Powered/>
            </div>
        </Container>
    )
}