import PropTypes from 'prop-types';
import { Container, Text } from './buttonAssets';
import { useNavigate } from 'react-router-dom';

const themes = {
    primary: {
        background: 'var(--deepblue)',
        border: 'var(--darkblue)',
        hover: 'var(--blue)',
        focus: 'var(--blue)',
    },
    secondary: {
        background: 'var(--lightblue)',
        border: 'var(--darkblue)',
        hover: 'var(--blue)',
        focus: 'var(--blue)',
    }
}


function Button({
    text,
    theme,
    handler
}) {
    const myTheme = themes[theme];
    const navigate = useNavigate();

    const clickHandler = (prop) => {
        if (typeof(prop) === 'string') {
            navigate(prop);
        }
        else {
            handler();
        }
    }

    return (
        <Container onClick={() => clickHandler(handler)} $bg={myTheme.background} $hoverBG={myTheme.hover} $border={myTheme.border}>
            <div className='circle'></div>
            <Text>{text}</Text>
        </Container>
    );
}

Button.propTypes = {
    // By default all props are optional. Please add the isRequired bool if you need them to be mandatory. 
    text: PropTypes.string.isRequired,
    theme: PropTypes.oneOf(['primary', 'secondary']).isRequired,
    handler: PropTypes.oneOf([PropTypes.func, PropTypes.string]).isRequired
}

Button.defaultProps = {
    text: 'Button',
    theme: 'primary',
    handler: defaultHandler
}

function defaultHandler() {
    console.log('button is clicked');
}

export default Button;