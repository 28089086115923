import { ViewWrapper, ContentContainer, TextContainer, TextCenter, ChapterSection, GraySection } from "../components/wrappers";
import layout from "../css-modules/layouts.module.css";
import { applyClasses } from "../utilities";

import Statistic from "../components/general/statistic";

import SplitScreen from "../components/layout/splitscreen";

import ChapterButton from "../components/general/chapterButton";
import ChapterNumber from "../components/view-specific/chapters/chapterNum";
import ChapterTitle from "../components/view-specific/chapters/chapterTitle";
import Navbar from "../components/general/navbar";
import SliderList from "../components/general/slider";
import { ch2Slider } from "../data";
import RenderFooter from "../components/general/renderFooter";
import CorrLine from "../components/CorrLine";

import { useEffect, useState } from 'react';

import { states } from "../data";
import ch1_1 from '../resources/chapters/1/img1.webp'
import ch3_1 from '../resources/chapters/3/img1.webp'
import Dropdown from "../components/general/dropdown";
import CareVisual from "../components/view-specific/ch2-care/care";

import ReactGA from "react-ga4";

function Chapter2() {

    const [currState, setCurrState] = useState('National');

    useEffect(() => {
      window.scrollTo(0,0);
      ReactGA.send({ hitType: "pageview", page: "/ch2", title: "Food Insecurity & Gender-based Violence" });
    }, []);

    return (
        <ViewWrapper>
            <Navbar/>

            <ChapterSection>
              <TextContainer>
                  <TextCenter>
                    <ChapterNumber number={2}/>
                    <ChapterTitle title="Food Insecurity & Gender-based Violence" />
                    {/*<ChapterTitle sub title='Circumstances that link to inequality' color={'var(--textColor)'}/> */}
                  </TextCenter>
              </TextContainer>
            </ChapterSection>

            <ChapterSection >
              <SliderList list={ch2Slider}/>
            </ChapterSection>

            <ChapterSection>
              <TextContainer>
                <p>As we have seen, UN data shows that women are at a substantially higher risk of food insecurity than men in Mexico. How is this inequality reflected in other aspects of womens’ lives? One area of profound concern is domestic violence. <a href='https://www.unwomen.org/en/what-we-do/ending-violence-against-women' target='_blank' rel='noreferrer'>According to the UN</a>, “One in three women worldwide experience physical or sexual violence, mostly by an intimate partner.” Yet fewer than half of them seek help after experiencing violence.</p>
              </TextContainer>
              <ContentContainer>
                <Statistic stat={'1 in 3'} desc='women worldwide experience physical or sexual violence, mostly by an intimate partner.' fill={'var(--darkblue)'}/>
              </ContentContainer>
              <TextContainer>
                <p>A <a href='https://www.care.org/wp-content/uploads/2022/11/GBV-food-security-brief_EN.pdf' target='_blank' rel='noreferrer'>2022 Care Report</a> outlines the detrimental impact of gender-based violence (GBV) on women's ability to procure sustenance. Instances of violence create physical and psychological barriers, limiting women's freedom of movement and constraining their ability to access food sources. The fear and trauma inflicted by GBV leave women hesitant or unable to engage in market activities, use community resources, or access nutritious food, exacerbating their vulnerability to malnutrition and hunger.</p>
              </TextContainer>
              <TextContainer>
                <p>Furthermore, the report highlights the reverse relationship between food insecurity and GBV. The scarcity of resources and inadequate access to food expose women and girls to precarious situations which increase their vulnerability to exploitation, abuse, and violence.</p>
              </TextContainer>
              
            </ChapterSection>

            <ChapterSection>
                <CareVisual/>
            </ChapterSection>

            <ChapterSection>
              <TextContainer>
                <p>What is the state of GBV in Mexico? The National Institute of Statistics and Geography (INEGI) reports on the <a target='_blank' rel='noreferrer' href='https://www.inegi.org.mx/programas/endireh/2021/#Tabulados'>frequency of women  age 15 and older women suffering incidents of violence</a> (psychological, economic or heritage, physical or sexual) in the family in the last 12 months. In 2020-21 this rate stood at 11.3%. The president of INEGI Graciela Marquez told a news conference: "Violence against women continues to be a challenge in the country and constitutes a public health problem." </p>
              </TextContainer>
            </ChapterSection>


            {/*<ChapterSection>
              <ChartSection>
                <StickyVis
                    source={<a href='https://www.coneval.org.mx/Medicion/Paginas/Pobreza-municipio-2010-2020.aspx'>CONEVAL</a>}
                >
                  <IO_List fill='var(--blue)' margin='800px 0px' data={ch1_desc_map} element={IoRenderer} callback={setMapFrameIndex}/>
                  <MapFrames state={mapFrameIndex}/>
                </StickyVis>
              </ChartSection>
    </ChapterSection> */}

            <ChapterSection>
              <TextContainer>
                <p>For this story, state level data is available for three time periods: 2010-11, 2015-16, and 2020-21. Looking at this data shows that the rate of violence against women declined sharply between 2010-11 and 2015-16 before rising in 2020-21, a pattern that is seen across all states. Our research suggests this decline was due in large measure to a methodology change and not a dramatic reduction in cases of GBV.</p>
              </TextContainer>
            </ChapterSection>
            


            <ChapterSection>
              <GraySection style={{paddingTop: '10vh', paddingBottom: '10vh'}}>
                    <ContentContainer>
                        <div className={applyClasses(layout, ['flexed-column', 'align-center'])}>
                            <Dropdown options={states} title="States" value={currState} stateHandler={setCurrState}/>
                        </div>
                        <CorrLine state={currState}/>
                    </ContentContainer>
              </GraySection>
            </ChapterSection>


            <ChapterSection>
              <SplitScreen gap="12.5vw" reversedColumn={true}>
                <ChapterButton
                      title="Previous Chapter"
                      subtitle="Food Insecurity in Mexican Women"
                      image={ch1_1}
                      handler={'/ch1'}
                  />
                  <ChapterButton
                      title="Next Chapter"
                      subtitle="Hunger is a Result of Inequity"
                      direction="backward"
                      image={ch3_1}
                      handler={'/ch3'}
                  />
              </SplitScreen>
            </ChapterSection>
            
            <RenderFooter/>
        </ViewWrapper>
    );
  }
  
  export default Chapter2;
  