import styled from 'styled-components';
import { device } from '../../../utilities';

const Heading = styled.h1`
    font-family: 'Replica', sans-serif;
    padding-left: 1em;
    text-indent: -1em;
    margin: 0px;
    color: var(--darkgray);
    line-height: 90%;
    font-weight: 400;
    letter-spacing: -0.25rem;
    font-size: clamp(4.25rem, 3.006rem + 5.529vw, 13.4rem);


    @media (max-width: 350px) {
        font-size: 3rem;
    }

    @media ${device.fold} {

        span {
            display: block;
            margin-left: -1em;
            text-indent: 1.8em;
        }
    }
`;

function Title({
    title
}) {

    return (
        <Heading>{title}</Heading>
    )
}

export default Title;