import styled from 'styled-components';
import { BiChevronDown } from 'react-icons/bi';
import { gsap } from 'gsap';
import { useEffect, useRef } from 'react';

const Container = styled.div`
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: auto;
`;

const Statement = styled.div`
    display: flex;
    background-color: ${props => props.$color};
    color: ${props => props.$text};
    border-radius: 10px;
    padding: 15px;
    margin: 0px;
    position: relative;
`;

const Flex = styled.div`
    display: flex;
    gap: 20px;
    justify-content: center;
    margin: 15px 0px;
`; 

function CareVisual() {
    return (
        <Container>
            <Statement $color='var(--orange)' $text={'#000'}>
                <p>GBV risks and gender inequality make women and girls less likely to access food</p>
            </Statement>
            <Flex>
                <Chev direction={'forward'} color='var(--deepblue)' colo text='Food Insecurity'/>
                <Chev direction={'backward'} color='var(--orange)' text='Gender-based violence'/>
            </Flex>
            <Statement $color='var(--deepblue)' $text={'#fff'}>
                <p>Food insecurity and gender inequality increase GBV risks for women and girls</p>
            </Statement>
        </Container>
    );
}

const ChevContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.85em;

    p {
        font-family: 'Georgia-Italic', serif;
    }
`;

const Circle = styled.div`
    width: 25px;
    height: 25px;
    background-color: ${props => props.$color};
    border-radius: 50%;
    font-size: 22px;
    overflow: hidden;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
`

function Chev({ text, direction, color }) {

    const chevron = useRef(null);

    useEffect(() => {
        const toOne = direction === 'forward' ? {y: 20, opacity: 0} : {y: -20, opacity: 0};
        const fromTwo = direction === 'forward' ? {y: -20, opacity: 0} : {y: 20, opacity: 0};

        const tl = gsap.timeline({repeat: -1, repeatDelay: 1});
            tl.fromTo(chevron.current,{y: 0, opacity: 1}, {...toOne, duration: 0.8});
            tl.fromTo(chevron.current,{...fromTwo}, {y: 0, opacity: 1, duration: 0.8});

        return function() {
            tl.kill();
        }
    }, [direction]);

    return (
        <ChevContainer>
            <Circle $color={color}>
                <div ref={chevron} $direction={direction}>
                    <BiChevronDown style={direction === 'forward' ? {transform: 'rotate(0deg)', color: '#fff'} : { transform: 'rotate(180deg)' }}/>
                </div>
            </Circle>
            <p>{text}</p>
        </ChevContainer>
    );
}

export default CareVisual;