const hungerViolence = [
  {
    avgViolence_National: 0.273,
    avgHunger_National: 0.228,
    avgViolence_Aguascalientes: 0.183,
    avgHunger_Aguascalientes: 0.281,
    "avgViolence_Baja California": 0.15,
    "avgHunger_Baja California": 0.222,
    "avgViolence_Baja California Sur": 0.235,
    "avgHunger_Baja California Sur": 0.236,
    avgViolence_Campeche: 0.287,
    avgHunger_Campeche: 0.264,
    avgViolence_Chiapas: 0.292,
    avgHunger_Chiapas: 0.176,
    avgViolence_Chihuahua: 0.163,
    avgHunger_Chihuahua: 0.276,
    "avgViolence_Ciudad de México": 0.146,
    "avgHunger_Ciudad de México": 0.259,
    avgViolence_Coahuila: 0.197,
    avgHunger_Coahuila: 0.232,
    avgViolence_Colima: 0.186,
    avgHunger_Colima: 0.303,
    avgViolence_Durango: 0.182,
    avgHunger_Durango: 0.252,
    "avgViolence_Estado de México": 0.294,
    "avgHunger_Estado de México": 0.368,
    avgViolence_Guanajuato: 0.208,
    avgHunger_Guanajuato: 0.237,
    avgViolence_Guerrero: 0.391,
    avgHunger_Guerrero: 0.259,
    avgViolence_Hidalgo: 0.27,
    avgHunger_Hidalgo: 0.236,
    avgViolence_Jalisco: 0.198,
    avgHunger_Jalisco: 0.265,
    avgViolence_Michoacán: 0.27,
    avgHunger_Michoacán: 0.291,
    avgViolence_Morelos: 0.206,
    avgHunger_Morelos: 0.275,
    avgViolence_Nayarit: 0.221,
    avgHunger_Nayarit: 0.343,
    "avgViolence_Nuevo León": 0.148,
    "avgHunger_Nuevo León": 0.26,
    avgViolence_Oaxaca: 0.241,
    avgHunger_Oaxaca: 0.25,
    avgViolence_Puebla: 0.26,
    avgHunger_Puebla: 0.277,
    avgViolence_Querétaro: 0.203,
    avgHunger_Querétaro: 0.255,
    "avgViolence_Quintana Roo": 0.193,
    "avgHunger_Quintana Roo": 0.279,
    "avgViolence_San Luis Potosí": 0.264,
    "avgHunger_San Luis Potosí": 0.263,
    avgViolence_Sinaloa: 0.221,
    avgHunger_Sinaloa: 0.235,
    avgViolence_Sonora: 0.247,
    avgHunger_Sonora: 0.32,
    avgViolence_Tabasco: 0.312,
    avgHunger_Tabasco: 0.232,
    avgViolence_Tamaulipas: 0.126,
    avgHunger_Tamaulipas: 0.245,
    avgViolence_Tlaxcala: 0.227,
    avgHunger_Tlaxcala: 0.269,
    avgViolence_Veracruz: 0.242,
    avgHunger_Veracruz: 0.263,
    avgViolence_Yucatán: 0.206,
    avgHunger_Yucatán: 0.279,
    avgViolence_Zacatecas: 0.216,
    avgHunger_Zacatecas: 0.3,
    period: "2010-2011",
    year: "2010",
  },
  {
    avgViolence_National: 0.103,
    avgHunger_National: 0.203,
    avgViolence_Aguascalientes: 0.168,
    avgHunger_Aguascalientes: 0.125,
    "avgViolence_Baja California": 0.133,
    "avgHunger_Baja California": 0.078,
    "avgViolence_Baja California Sur": 0.205,
    "avgHunger_Baja California Sur": 0.068,
    avgViolence_Campeche: 0.225,
    avgHunger_Campeche: 0.089,
    avgViolence_Chiapas: 0.23,
    avgHunger_Chiapas: 0.076,
    avgViolence_Chihuahua: 0.123,
    avgHunger_Chihuahua: 0.098,
    "avgViolence_Ciudad de México": 0.087,
    "avgHunger_Ciudad de México": 0.097,
    avgViolence_Coahuila: 0.15,
    avgHunger_Coahuila: 0.116,
    avgViolence_Colima: 0.188,
    avgHunger_Colima: 0.097,
    avgViolence_Durango: 0.169,
    avgHunger_Durango: 0.124,
    "avgViolence_Estado de México": 0.189,
    "avgHunger_Estado de México": 0.123,
    avgViolence_Guanajuato: 0.243,
    avgHunger_Guanajuato: 0.088,
    avgViolence_Guerrero: 0.391,
    avgHunger_Guerrero: 0.092,
    avgViolence_Hidalgo: 0.2,
    avgHunger_Hidalgo: 0.108,
    avgViolence_Jalisco: 0.137,
    avgHunger_Jalisco: 0.128,
    avgViolence_Michoacán: 0.291,
    avgHunger_Michoacán: 0.123,
    avgViolence_Morelos: 0.24,
    avgHunger_Morelos: 0.099,
    avgViolence_Nayarit: 0.177,
    avgHunger_Nayarit: 0.095,
    "avgViolence_Nuevo León": 0.146,
    "avgHunger_Nuevo León": 0.069,
    avgViolence_Oaxaca: 0.308,
    avgHunger_Oaxaca: 0.113,
    avgViolence_Puebla: 0.237,
    avgHunger_Puebla: 0.111,
    avgViolence_Querétaro: 0.164,
    avgHunger_Querétaro: 0.115,
    "avgViolence_Quintana Roo": 0.212,
    "avgHunger_Quintana Roo": 0.1,
    "avgViolence_San Luis Potosí": 0.17,
    "avgHunger_San Luis Potosí": 0.09,
    avgViolence_Sinaloa: 0.22,
    avgHunger_Sinaloa: 0.098,
    avgViolence_Sonora: 0.217,
    avgHunger_Sonora: 0.087,
    avgViolence_Tabasco: 0.386,
    avgHunger_Tabasco: 0.081,
    avgViolence_Tamaulipas: 0.186,
    avgHunger_Tamaulipas: 0.081,
    avgViolence_Tlaxcala: 0.201,
    avgHunger_Tlaxcala: 0.1,
    avgViolence_Veracruz: 0.285,
    avgHunger_Veracruz: 0.111,
    avgViolence_Yucatán: 0.16,
    avgHunger_Yucatán: 0.091,
    avgViolence_Zacatecas: 0.189,
    avgHunger_Zacatecas: 0.095,
    period: "2015-2016",
    year: "2015",
  },
  {
    avgViolence_National: 0.114,
    avgHunger_National: 0.195,
    avgViolence_Aguascalientes: 0.163,
    avgHunger_Aguascalientes: 0.129,
    "avgViolence_Baja California": 0.12,
    "avgHunger_Baja California": 0.086,
    "avgViolence_Baja California Sur": 0.218,
    "avgHunger_Baja California Sur": 0.084,
    avgViolence_Campeche: 0.234,
    avgHunger_Campeche: 0.107,
    avgViolence_Chiapas: 0.208,
    avgHunger_Chiapas: 0.065,
    avgViolence_Chihuahua: 0.12,
    avgHunger_Chihuahua: 0.116,
    "avgViolence_Ciudad de México": 0.15,
    "avgHunger_Ciudad de México": 0.15,
    avgViolence_Coahuila: 0.144,
    avgHunger_Coahuila: 0.116,
    avgViolence_Colima: 0.16,
    avgHunger_Colima: 0.116,
    avgViolence_Durango: 0.176,
    avgHunger_Durango: 0.107,
    "avgViolence_Estado de México": 0.199,
    "avgHunger_Estado de México": 0.109,
    avgViolence_Guanajuato: 0.214,
    avgHunger_Guanajuato: 0.109,
    avgViolence_Guerrero: 0.326,
    avgHunger_Guerrero: 0.15,
    avgViolence_Hidalgo: 0.247,
    avgHunger_Hidalgo: 0.117,
    avgViolence_Jalisco: 0.126,
    avgHunger_Jalisco: 0.116,
    avgViolence_Michoacán: 0.2,
    avgHunger_Michoacán: 0.122,
    avgViolence_Morelos: 0.217,
    avgHunger_Morelos: 0.115,
    avgViolence_Nayarit: 0.178,
    avgHunger_Nayarit: 0.109,
    "avgViolence_Nuevo León": 0.131,
    "avgHunger_Nuevo León": 0.096,
    avgViolence_Oaxaca: 0.268,
    avgHunger_Oaxaca: 0.12,
    avgViolence_Puebla: 0.256,
    avgHunger_Puebla: 0.106,
    avgViolence_Querétaro: 0.159,
    avgHunger_Querétaro: 0.146,
    "avgViolence_Quintana Roo": 0.26,
    "avgHunger_Quintana Roo": 0.099,
    "avgViolence_San Luis Potosí": 0.162,
    "avgHunger_San Luis Potosí": 0.095,
    avgViolence_Sinaloa: 0.205,
    avgHunger_Sinaloa: 0.109,
    avgViolence_Sonora: 0.199,
    avgHunger_Sonora: 0.123,
    avgViolence_Tabasco: 0.392,
    avgHunger_Tabasco: 0.113,
    avgViolence_Tamaulipas: 0.128,
    avgHunger_Tamaulipas: 0.079,
    avgViolence_Tlaxcala: 0.26,
    avgHunger_Tlaxcala: 0.119,
    avgViolence_Veracruz: 0.223,
    avgHunger_Veracruz: 0.138,
    avgViolence_Yucatán: 0.216,
    avgHunger_Yucatán: 0.114,
    avgViolence_Zacatecas: 0.153,
    avgHunger_Zacatecas: 0.088,
    period: "2020-2021",
    year: "2020",
  },
];

hungerViolence.forEach((entry) => {
  entry.year = new Date(entry.year, 0);
});

export default hungerViolence;
