import React, { useEffect } from 'react';
import styled from 'styled-components';
import colorMap from '../../../resources/svg/mexico.svg'
import { animate } from './d3/mapFunctions';
import { useSVG, useData } from './hooks/customHooks';

const MapContainer = styled.div`
  /* Add your styles for the map container */
  position: relative;
`;

const ColorMap = () => {

    const { data } = useSVG(colorMap);
    const { container, svgRef, startAnim, triggerAnim } = useData(data);

    useEffect(() => {
        if (startAnim) {
            animate(svgRef.current);
        }
        return () => {
            triggerAnim(false);
        }
    }, [startAnim, svgRef.current]);

    return (
        <MapContainer ref={container}>
        </MapContainer>
    );
};

export default ColorMap;
