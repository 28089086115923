import { svg } from 'd3';
import { useState, useEffect, useRef } from 'react';
import { setUp, cleanUp } from '../d3/mapFunctions';
import { palette } from '../../../../utilities';

const useSVG = (SVG) => {
    const [data, setData] = useState(null);
    useEffect(() => {
        svg(SVG).then((data) => {
            setData(data);
        });

        return () => {
            setData(null);
        }
    },  [SVG]); 
    return {
        data
    }
}

const useData = (loadedSVG) => {
    const svgRef = useRef(null);
    const container = useRef(null);
    const [startAnim, triggerAnim] = useState(false);

    useEffect(() => {
        if (loadedSVG) {
            setUp(container, loadedSVG, svgRef, palette, triggerAnim, startAnim);
        }
        return () => {
            cleanUp(container);
        }
    }, [loadedSVG]);

    return {
        startAnim,
        svgRef,
        container,
        triggerAnim
    }
}

export {
    useSVG,
    useData
}
