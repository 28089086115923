import { ViewWrapper } from "../components/wrappers";
import Menu from "../components/general/menu";

import Landing from "../components/view-specific/landing/landing";

import { useContext, useEffect } from 'react';
import { AppContext } from '../components/contexts';

import { chapterList } from "../data";
import ReactGA from "react-ga4";

function Home() {

    const { menuOpen } = useContext(AppContext);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/", title: "Hunger & Gender in Mexico" });
    }, []);

    return (
        <ViewWrapper>
            <Menu fill='var(--deepblue)' list={chapterList} transition={menuOpen}/>
            <Landing 
                title={<>Hunger & <br/> <span>Gender in Mexico</span></>}
                desc="Despite national and international efforts to bridge the gender gap, Mexican women remain vulnerable to malnutrition (hunger) and food insecurity. This project explores the issue in an interactive data story, highlighting the scale of the problem and its relationship with other SDGs that impact the lives of Mexican women."
            />
        </ViewWrapper>
    );
  }

  export default Home;
