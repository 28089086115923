import styled from 'styled-components';
import SplitScreen from "../layout/splitscreen";
import PropTypes from 'prop-types';
import { ChapterSection } from '../wrappers';
import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import ReactIntersectionObserver from '../layout/intersectionObserver';
import { device } from '../../utilities';

const Container = styled.div`
`

const Heading = styled.h2`
    font-family: "Replica-Bold", sans-serif;
    color: ${props => props.$fill};
    font-size: 4.75em;
    margin: 0px;
    transform: translateY(-60px);
    opacity: 0;

    @media screen and (max-width: 500px) {
        font-size: 3.75em;
    }
`;

const Desc = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${props => props.$fill};
    transform: translateY(-40px);
    opacity: 0;

    p {
        font-size: 1.5em;
        margin: 0px;
    }

    @media ${device.tablet} {
        padding-left: 20px;
    }

    @media screen and (max-width: 500px) {
        font-size: 0.9em;
    }
`;

function Statistic({ stat, desc, fill }) {

    const heading = useRef(null);
    const descRef = useRef(null);

    useEffect(() => {
    }, []);

    return (
        <ChapterSection>
            <SplitScreen align='center' mobileAlign='flex-start' leftWeight={1} rightWeight={3.15}>
                <ReactIntersectionObserver ratio={0.7}
                    callback={() => gsap.to(heading.current, {y: 0, opacity: 1, duration: 0.7, delay: 0.1})}
                >
                    <Heading ref={heading} $fill={fill}>{stat}</Heading>
                </ReactIntersectionObserver>
                <ReactIntersectionObserver ratio={0.7}
                    callback={() => gsap.to(descRef.current, {y: 0, opacity: 1, duration: 0.7, delay: 0.25})}
                >
                    <Desc ref={descRef} $fill={fill}><p>{desc}</p></Desc>
                </ReactIntersectionObserver>
            </SplitScreen>
        </ChapterSection>
    );
}

Statistic.propTypes = {
    stat: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    fill: PropTypes.string.isRequired
}

Statistic.defaultProps = {
    stat: '26.1%', 
    desc: 'of Mexico’s population faced moderate to severe food insecurity in 2020',
    fill: '#00BAB5'
}

export default Statistic;
