import styled from 'styled-components';
import { device } from '../../utilities';

export const Container = styled.button`
    display: flex;
    align-items: center;
    width: fit-content;
    min-width: 130px;
    cursor: pointer;
    position: relative;
    background: none;
    border: none;
    padding: 0px;

    @media ${device.fold} {
        margin: 4px 0px;
    }

    @media ${device.laptop} {
        margin: 0px;
    }

    @media ${device.tablet} and ${device.tabletH} {
        margin: 8px 0px;
    }

    .circle {
        position: relative;
        border: 1px solid ${props => props.$border};
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-right: 10px;
    }

    .circle:before {
        content: '';
        position: absolute;
        transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
        background-color: ${props => props.$bg};
        width: 25px;
        height: 25px;
        top: 5px;
        left: 5px;
        border-radius: 50%;
    }

    &:hover {
        .circle:before {
            transform: scale(0.4);
            background-color: ${props => props.$hoverBG};
        }
    }

    &:focus {
        .circle:before {
            background-color: ${props => props.$focus};
        }
    }

    @media ${device.desktop} {
        .circle {
            width: 55px;
            height: 55px;
        }


        .circle:before {
            width: 40px;
            height: 40px;
            top: 7.5px;
            left: 7.5px;
        }
    }
`;

export const Text = styled.p`
    color: inherit;
    position: relative;
    margin: 0px;
    font-size: 1.1em;
    z-index: 2;

    @media ${device.laptopL} {
        font-size: 1.4em;
    }
`;